import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  imagesCarousel: {
    "& .slide": {
      height: 382,
      padding: theme.spacing(0, 0.5),
      backgroundColor: "transparent !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        height: 214,
      },
    },
    "& .slider-wrapper": {
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#3f3d56",
      },
    },
    "& ul.slider": {
      [theme.breakpoints.down("md")]: {
        margin: 0,
        width: "100%",
      },
    },
  },
  singleImageCarouselContainer: {
    "& li.slide": {
      padding: 0,
    },
  },
  imagesCarouselImage: {
    borderRadius: 0,
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
    },
  },
  singleImage: {
    [theme.breakpoints.down("md")]: {
      objectFit: "contain",
    },
  },
  arrowButton: {
    position: "absolute",
    zIndex: 2,
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: theme.palette.common.white,
    color: "#3f3d56",
    borderRadius: "50%",
    padding: 8,
    opacity: 0.4,
    transition: "opacity 0.22s ease-in-out",
    height: "auto",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      opacity: 1,
    },
    "&.right": {
      right: theme.spacing(1.5),
    },
    "&.left": {
      left: theme.spacing(1.5),
    },
  },
}));
