import React from "react";

import { Grid, Link, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import UserAvatar from "@/common/components/UserAvatar";

import { getLocalDateFromUtc } from "@/common/services";
import { getFullAuthorName } from "@/common/services/fullName";
import { UserBaseFragment } from "@/graphql/types";

import { CommentUnion } from "../types/CommentUnion";

const useStyles = makeStyles()((theme: Theme) => ({
  commentContainer: {
    display: "flex",
  },
  bodyWrapper: {
    padding: theme.spacing(1.5),
    borderRadius: "1rem",
    backgroundColor: theme.mode.background.comment,
    marginLeft: theme.spacing(1.5),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 240,
  },
  body: {
    fontStyle: "italic",
    lineHeight: "1.25rem",
  },
  rootContainer: {
    marginBottom: theme.spacing(1.5),
  },
  commentFooter: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
  },
  date: {
    fontSize: "0.6875rem",
    fontWeight: 600,
    color: "#bebec5",
  },
  ownerName: {
    fontSize: "0.9375rem",
    fontWeight: "bold",
    color: theme.mode.text.heading,
    lineHeight: "1.25rem",
  },
  defaultCursor: {
    cursor: "default",
  },
  avatar: {
    width: 32,
    height: 32,
  },
}));

export type DeletedCommentViewProps = {
  comment: CommentUnion;
  variant: "comment" | "reply";
};

function DeletedCommentView(props: DeletedCommentViewProps) {
  const { classes } = useStyles();
  const { comment } = props;
  const { t, i18n } = useTranslation();

  const getCommentLink = (comment: CommentUnion) => {
    if (comment.anonymous) {
      return "";
    }
    return `/profiles/${comment.author?.slug}`;
  };

  const LinkToProfile = ({ children }: any) => {
    return comment.anonymous || comment.author?.deleted ? (
      <Link className={classes.defaultCursor} underline="none">
        {children}
      </Link>
    ) : (
      <Link href={getCommentLink(comment)} underline="none">
        {children}
      </Link>
    );
  };

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      key={comment.id}
      className={classes.rootContainer}>
      <Grid item>
        <LinkToProfile>
          <UserAvatar
            classes={{ root: classes.avatar }}
            isAnonymous={comment.anonymous}
            user={comment.author}
          />
        </LinkToProfile>
      </Grid>
      <Grid item container direction="column">
        <Grid item className={classes.commentContainer}>
          <div className={classes.bodyWrapper}>
            <LinkToProfile>
              <span className={classes.ownerName}>
                {getFullAuthorName(comment.author as UserBaseFragment)}
              </span>
            </LinkToProfile>
            <Typography variant="body1" className={classes.body}>
              <i>{t("Timeline.DeletedCommentText")}</i>
            </Typography>
          </div>
        </Grid>
        <Grid item className={classes.commentFooter}>
          <span className={classes.date}>
            <Moment locale={i18n.language} fromNow>
              {getLocalDateFromUtc(comment.createdAt)}
            </Moment>
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DeletedCommentView;
