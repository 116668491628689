import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  playerWrapper: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  video: {
    width: "100%",
    maxHeight: "70vh",
    height: "100%",
    objectFit: "contain",
    "&:-webkit-full-screen": {
      maxHeight: "unset",
      maxWidth: "unset",
    },
  },
  file: {
    display: "inline-grid",
    gridTemplateColumns: "24px 1fr",
    gap: theme.spacing(1),
    alignItems: "center",
    padding: theme.spacing(0.25, 1, 0.25, 0.25),
    borderRadius: 4,
    backgroundColor: "transparent",
    transition: "background-color 0.22s ease-in-out",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}1f`,
    },
  },
  fileIcon: {
    color: theme.palette.primary.main,
  },
  fileName: {
    color: theme.mode.text.description,
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    lineHeight: "1.25rem",
    fontSize: "1.0625rem",
    fontWeight: 600,
  },
}));

export default useStyles;
