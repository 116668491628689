import { useState, useCallback, useEffect, useRef } from "react";

type UseViewportIntersectionMonitorProps = {
  options?: IntersectionObserverInit;
  timeout?: number;
  enter?: () => void;
  leave?: () => void;
};

export const useViewportIntersectionMonitor = ({
  options = {},
  timeout = 0,
  enter,
  leave,
}: UseViewportIntersectionMonitorProps) => {
  const observerRef = useRef<IntersectionObserver>();
  const isActiveRef = useRef<boolean>(false);
  const [element, setElement] = useState<Element>();

  let timeoutId: ReturnType<typeof setTimeout>;

  const toggleActive = useCallback(
    () => (isActiveRef.current = !isActiveRef.current),
    [isActiveRef],
  );

  const handleElement = useCallback((node: Element) => {
    setElement(node);
  }, []);

  const handleEnter = () => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      toggleActive();
      enter && enter();
    }, timeout);
  };

  const handleLeave = () => {
    clearTimeout(timeoutId);

    if (isActiveRef.current) {
      toggleActive();
      leave && leave();
    }
  };

  const handleIntersection = (isIntersecting: boolean) => {
    isIntersecting ? handleEnter() : handleLeave();
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => handleIntersection(entries[0]?.isIntersecting),
      options,
    );

    return () => observerRef.current?.disconnect();
  }, []);

  useEffect(() => {
    if (element) {
      observerRef.current?.observe(element);
    }
  }, [handleElement, element]);

  return {
    handleElement,
  };
};
