import React from "react";

import { Select, SelectProps, MenuItem, styled, InputBase } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import ChevronDown from "@/common/icons/ChevronDown";

const useStyles = makeStyles()(() => ({
  menuItemGutters: {
    paddingLeft: "0.875rem",
    paddingRight: "0.875rem",
  },
  menuItemRoot: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "&:first-of-type": {
      borderRadius: "1rem 1rem 0 0",
    },
    "&:last-of-type": {
      borderRadius: "0 0 1rem 1rem",
    },
  },
}));

const CustomInput = styled(InputBase)(({}) => ({
  "& .MuiInputBase-input": {
    border: "none",
    "&:focus": {
      border: "none",
    },
  },
}));

interface CustomSelectWithChildrenProps extends SelectProps {
  options?: never;
  optionsClassName?: never;
}

interface CustomSelectWithOptionsProps extends SelectProps {
  options: Array<{ id: string | number; name: string }>;
  optionsClassName?: string;
  children?: never;
}

const CustomSelect = ({
  children,
  options,
  optionsClassName,
  ...delegated
}: CustomSelectWithChildrenProps | CustomSelectWithOptionsProps) => {
  const { classes } = useStyles();

  return (
    <Select
      IconComponent={(props) => <ChevronDown {...props} />}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
      input={<CustomInput />}
      {...delegated}>
      {children ||
        (options &&
          options.map(({ id, name }) => (
            <MenuItem
              value={id}
              key={id}
              className={optionsClassName}
              classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}>
              {name}
            </MenuItem>
          )))}
    </Select>
  );
};

export default CustomSelect;
