import React, { useEffect } from "react";

import { IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import RemoveIcon from "@/common/icons/RemoveIcon";

import { Embed } from "@/graphql/types";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  embedWrapper: {
    display: "flex",
    marginBlock: theme.spacing(1),
    justifyContent: "center",
  },

  embed: {
    width: "100%",
    flex: 1,
  },

  embedDisplay: {
    maxWidth: "500px",
  },

  deleteButton: {
    paddingRight: 0,
  },
}));

type PostEmbedsProps = {
  embeds: Embed[];
  allowEditing?: boolean;
  handleDelete?: (url: string) => void;
};

export const PostEmbeds = ({ embeds, allowEditing, handleDelete }: PostEmbedsProps) => {
  const { classes, cx } = useStyles();

  useEffect(() => {
    // @ts-ignore
    window.iframely && window.iframely.load();
  }, [embeds]);

  return embeds.length ? (
    <section className={classes.wrapper}>
      {embeds.map((embed) => (
        <div key={embed.url} className={classes.embedWrapper}>
          <div
            dangerouslySetInnerHTML={{ __html: embed.html }}
            className={cx(classes.embed, !allowEditing && classes.embedDisplay)}
          />
          <div>
            {allowEditing && (
              <IconButton
                classes={{ root: classes.deleteButton }}
                onClick={() => handleDelete && handleDelete(embed.url)}
                size="large">
                <RemoveIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </section>
  ) : null;
};
