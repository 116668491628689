import { Delta } from "quill";
import twemoji from "twemoji";

import { UNICODE_EMOJI_REGEX, IMG_SRC_REGEX } from "@/text-editor/constants";

/**
 * Given a delta searchs if it contains an insert op and returns its value.
 * @param {Quill Delta} delta
 */
const getInsertsFromDelta = ({ ops }: Delta) => {
  return (
    ops
      ?.filter((op) => op.insert && typeof op.insert === "string" && op.insert !== "")
      .map(({ insert }) => insert) || []
  );
};

export const getDeleteRetainFromDelta = ({ ops }: Delta) => {
  if (ops?.find((op) => op.insert)) return null;
  const deleteOp = ops?.find((op) => op.delete);
  if (deleteOp) return deleteOp;
  return null;
};

/**
 * Given a delta searchs if it contains an insert op and returns its value.
 * @param {Quill Delta} delta
 */
const getRetainFromDelta = ({ ops }: Delta) => {
  const insertOp = ops?.find((op) => op.retain);
  const deleteOp = ops?.find((op) => op.delete);

  return (insertOp?.retain || 0) + (deleteOp?.delete || 0);
};

export type UnicodeEmojiData = {
  retain?: number;
  unicodeEmoji: string;
  length: number;
  hasLeadingSpace: boolean;
};
/**
 * Given a Quill Delta checks if delta op is an insert op and if the inserted value
 * is a unicode emoji.
 * If both conditions are valid, returns an object with the inserted emoji's data
 * needed to make a replacement:
 * 	- retain			: retained chars before emoji
 *  - unicodeEmoji		: the original unicode emoji string
 *  - length			: the unicode emoji string length
 *  - hasLeadingSpace	:
 *
 * @param {Quill Delta} delta
 */
export const getInsertedUnicodeEmojiData = (delta: Delta): UnicodeEmojiData[] => {
  const data: UnicodeEmojiData[] = [];
  const inserts = getInsertsFromDelta(delta);

  if (inserts.length === 0) return data;

  const initialRetain = getRetainFromDelta(delta);
  inserts
    .reduce((matches, insert) => {
      return [...matches, ...insert.matchAll(UNICODE_EMOJI_REGEX)];
    }, [])
    .forEach((match: RegExpMatchArray, index: number) => {
      data.push({
        retain:
          initialRetain + (match.index || 0) - index - (match.input?.indexOf(" ") === 0 ? 1 : 0),
        unicodeEmoji: match[0],
        length: match[0].length,
        hasLeadingSpace: match.input?.indexOf(" ") === 0,
      });
    });

  return data;
};

/**
 *
 * @param {String} unicodeEmoji
 */
export const getTwemojiVersion = (unicodeEmoji: string | HTMLElement) => {
  const parsedTwemoji = twemoji.parse(unicodeEmoji, {
    folder: "svg",
    ext: ".svg",
    size: "16x16",
    callback: (icon: string, options: { base?: string; ext?: string; size?: string | number }) => {
      switch (icon) {
        case "a9": // © copyright
        case "ae": // ® registered trademark
        case "2122": // ™ trademark
          return false;
      }
      return `${options.base}${options.size}/${icon}${options.ext}`;
    },
  });
  const stringifiedTweemoji =
    typeof parsedTwemoji === "string" ? parsedTwemoji : parsedTwemoji.outerHTML;
  const twemojiSrcImage = IMG_SRC_REGEX.exec(stringifiedTweemoji);
  if (!twemojiSrcImage || twemojiSrcImage.length < 2) return false;

  return twemojiSrcImage[1];
};

/**
 * Replace twemoji with emoji (unicode)
 * @param {String} html
 * @returns {String}
 */

export const replaceTweemojiWithEmoji = (html: string) => {
  const twemojiRegex = /<img[^<]+class=[\\/"'][^<]*\s*emoji\s*[^<]*[\\/"'][^<]+>/gi;
  const imageAltRegex = /<img.*?alt=[\\/"'](.*?)[\\/"'][^>]+>/;
  return html.replaceAll(twemojiRegex, (twemoji) => twemoji.replace(imageAltRegex, "$1"));
};

export const maybeParseJsonTextEditorContent = (content?: string | null): string | Delta => {
  if (content) {
    try {
      return JSON.parse(content);
    } catch {
      return content;
    }
  } else {
    return "";
  }
};
