import React from "react";

import { Box, CardContent, Chip } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Attachments } from "@/attachments/components/attachments/Attachments";
import ContentCollapse from "@/common/components/ContentCollapse";
import Spinner from "@/common/components/Spinner";
import { PostProps } from "@/posts/components/PostCard";
import TextEditor from "@/text-editor/components/TextEditor";

import { maybeParseJsonTextEditorContent } from "@/text-editor/services";

import useStyles from "./PostCardContent.styles";

type PostCardContentType = PostProps & { loading: boolean };

export const PostCardContent = ({ post, loading }: PostCardContentType) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const postContent = maybeParseJsonTextEditorContent(post.body);

  return (
    <CardContent className={classes.cardContent}>
      {loading ? (
        <div className={classes.loading}>
          <Spinner variant="small" />
        </div>
      ) : (
        <>
          <Box p={0} className={classes.textContainer}>
            {(postContent as string).length > 0 && (
              <ContentCollapse key={postContent as string}>
                <TextEditor value={postContent} readonly theme="bubble" hideToolbar />
              </ContentCollapse>
            )}

            {(post.postTags || []).map((tag) => (
              <Chip
                key={tag.name}
                className={classes.chip}
                size="small"
                label={t(tag.translationKey)}
              />
            ))}
          </Box>

          <Attachments
            {...post}
            imagesVariant="carousel"
            classes={{ imageCarousel: classes.imageCarousel, files: classes.files }}
          />
        </>
      )}
    </CardContent>
  );
};
