import React, { createContext, useState, ReactNode } from "react";

type PostContextProps = {
  postOutdated: boolean;
  postCommentsOutdated: boolean;
  ignoreNewCommentUpdates: boolean;
  setPostOutdated: (flag: boolean) => void;
  setPostCommentsOutdated: (flag: boolean) => void;
  setIgnoreNewCommentUpdates: (flag: boolean) => void;
};

export const PostContext = createContext<PostContextProps>({} as PostContextProps);

const PostProvider = ({ children }: { children: ReactNode }) => {
  const [postOutdated, setPostOutdated] = useState<boolean>(false);
  const [postCommentsOutdated, setPostCommentsOutdated] = useState<boolean>(false);
  const [ignoreNewCommentUpdates, setIgnoreNewCommentUpdates] = useState<boolean>(false);

  return (
    <PostContext.Provider
      value={{
        postOutdated,
        postCommentsOutdated,
        ignoreNewCommentUpdates,
        setPostOutdated,
        setPostCommentsOutdated,
        setIgnoreNewCommentUpdates,
      }}>
      {children}
    </PostContext.Provider>
  );
};

export default PostProvider;
