import React, { useState, useRef } from "react";

import { Box, Grid, IconButton, Link, Typography, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import { Attachments } from "@/attachments/components/attachments/Attachments";
import CommentActions from "@/comments/components/CommentActions";
import CommentReplyList from "@/comments/components/CommentReplyList";
import DeletedCommentView from "@/comments/components/DeletedCommentView";
import { Button } from "@/common/components/button/Button";
import ContentCollapse from "@/common/components/ContentCollapse";
import Hide from "@/common/components/Hide";
import Spinner from "@/common/components/Spinner";
import { TransitionedEntity } from "@/common/components/TransitionedEntity/TransitionedEntity";
import UserAvatar from "@/common/components/UserAvatar";
import { UserListModal } from "@/common/components/UserListModal/UserListModal";
import { ReadOnlyProps } from "@/posts/components/PostCard";
import { FollowUserButton } from "@/posts/components/PostReactionBar";
import TextEditor from "@/text-editor/components/TextEditor";

import HeartIcon from "@/common/icons/HeartIcon";
import VerifiedIcon from "@/common/icons/VerifiedIcon";

import { useCommentsLikeStore } from "@/comments/hooks/use-comments-like-store";
import { bindDialogState, useDialog } from "@/common/hooks";

import { isNotAReply } from "@/comments/services/comments";
import { CommentUnion } from "@/comments/types/CommentUnion";
import { ActivityCardFragment, ActivityCardSlimFragment } from "@/common/models/ActivityModel";
import { getLocalDateFromUtc } from "@/common/services";
import { getFullAuthorName } from "@/common/services/fullName";
import { CommentReplyFragment, PostCommentFragment, UserBaseFragment } from "@/graphql/types";

import useStyles from "./CommentView.styles";

export type CommentViewProps<C extends CommentUnion, P extends CommentUnion> = {
  comment: C;
  variant: "comment" | "reply";
  parentComment?: P;
  onFollowableInteraction?: () => void;
  onReplyClick?: () => void;
  postActivity?: ActivityCardFragment | ActivityCardSlimFragment;
  loading?: boolean;
  hideDeleted?: boolean;
  handleLike?: (comment: C) => void;
  handleEdit?: () => void;
  handleReplyEdit?: (reply: CommentReplyFragment) => void;
  handleOnReply?: (
    comment: PostCommentFragment | CommentReplyFragment,
    parentCommentId: string,
  ) => void;
  handleDelete?: (comment: C) => void;
  isHighlighted?: boolean;
  highlightedReplyId?: string;
  isRestricted?: boolean;
  onRestrictedAction?: () => void;
} & ReadOnlyProps;

export const CommentView = <C extends CommentUnion, P extends CommentUnion>({
  comment,
  variant,
  isReadonly,
  postActivity,
  onFollowableInteraction,
  onReadonlyInteraction,
  onReplyClick,
  loading,
  hideDeleted,
  handleLike,
  handleEdit,
  handleReplyEdit,
  handleOnReply,
  handleDelete,
  isHighlighted,
  highlightedReplyId,
  isRestricted,
  onRestrictedAction,
}: CommentViewProps<C, P>) => {
  const { classes, cx } = useStyles();
  const [showReplies, setShowReplies] = useState(!!highlightedReplyId);
  const { t, i18n } = useTranslation();
  const postReplyListRef = useRef<any>();
  const theme = useTheme();
  const userListDialogState = useDialog();
  const { users, hasMorePages, fetch, fetchMore } = useCommentsLikeStore(comment);

  const onEditComment = async (reply?: C) => {
    if (comment.__typename === "Comment") {
      if (!handleEdit || !comment) return;
      handleEdit();
    } else if (comment.__typename === "CommentReply") {
      if (!handleReplyEdit || !reply) return;
      handleReplyEdit(reply as CommentReplyFragment);
    }
    const el = document.getElementById(`comment_${comment.id}`);
    if (el) el.id = `comment_${comment.id}_editing`;
  };

  const onEditReply = async (reply: CommentReplyFragment) => {
    if (!handleReplyEdit || !reply) return;
    handleReplyEdit(reply);
    const el = document.getElementById(`comment_${reply.id}`);
    if (el) el.id = `comment_${reply.id}_editing`;
  };

  const handleOnLikeClick = () => {
    if (isReadonly) return onReadonlyInteraction && onReadonlyInteraction();
    if (loading) {
      return;
    }
    onFollowableInteraction && onFollowableInteraction();
    return handleLike && handleLike(comment);
  };

  const handleReplyClick = () => {
    if (isReadonly) return onReadonlyInteraction && onReadonlyInteraction();
    handleOnReply && handleOnReply(comment as PostCommentFragment | CommentReplyFragment, "");
    if (isNotAReply(comment)) {
      setShowReplies(true);
      postReplyListRef?.current?.focusWriteCommentField();
    }
  };

  const getCommentLink = (comment: CommentUnion) => {
    if (comment.anonymous || comment.author?.deleted) {
      return undefined;
    }
    return `/profiles/${comment.author?.slug}`;
  };

  const LinkToProfile = ({ children }: any) => {
    return comment.anonymous || comment.author?.deleted ? (
      <Link className={classes.defaultCursor} underline="none">
        {children}
      </Link>
    ) : (
      <Link href={getCommentLink(comment)} underline="none">
        {children}
      </Link>
    );
  };

  const handleLikesCountClick = () => {
    fetch();
    userListDialogState.open();
  };

  if (comment.deleted) {
    return hideDeleted ? null : <DeletedCommentView variant={variant} comment={comment} />;
  }

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      key={comment.id}
      classes={{ root: classes.section }}
      className={isNotAReply(comment) ? classes.commentSection : classes.replySection}
      id={`comment_${comment.id}`}
      data-cy="Comment">
      <Grid item>
        <LinkToProfile>
          <UserAvatar
            className={classes.avatar}
            isAnonymous={comment.anonymous}
            user={comment.author}
            badgeStyles={{
              width: 8,
              height: 8,
            }}
          />
        </LinkToProfile>
      </Grid>
      <Grid item container direction="column">
        <Grid item className={classes.commentContainer}>
          <div
            className={cx(
              classes.bodyWrapper,
              "toHighlight",
              isHighlighted && classes.commentHighlighted,
            )}>
            <div className="header">
              <LinkToProfile>
                <span className={classes.ownerName}>
                  {getFullAuthorName(comment.author as UserBaseFragment, comment.anonymous)}
                  {!comment.anonymous && comment.author && comment.author.verified && (
                    <VerifiedIcon />
                  )}
                </span>
              </LinkToProfile>
              {isRestricted || (
                <Box className={classes.moreActionsContainer}>
                  <CommentActions
                    comment={comment}
                    handleEdit={onEditComment}
                    handleDelete={handleDelete}
                    postActivity={postActivity}
                  />
                </Box>
              )}
            </div>
            <ContentCollapse key={comment.body} gradientColor={theme.mode.background.comment}>
              <Typography component="span" variant="body1">
                {comment.body && (
                  <TextEditor
                    value={comment.body}
                    readonly
                    hideToolbar
                    theme="bubble"
                    className={classes.body}
                  />
                )}
              </Typography>
            </ContentCollapse>

            <Attachments {...comment} classes={{ imageCarousel: classes.imageCarousel }} />

            {comment.likes.likesCount > 0 && (
              <>
                <Button
                  variant="formless"
                  className={classes.likesCount}
                  onClick={handleLikesCountClick}>
                  <div className={classes.likesCountIcon}>
                    <HeartIcon width={12} height={12} fill="white" />
                  </div>
                  <TransitionedEntity
                    classes={{
                      root: classes.likesCountNumberContainer,
                      entity: classes.likesCountNumber,
                    }}>
                    {comment.likes.likesCount}
                  </TransitionedEntity>
                </Button>
                <UserListModal
                  {...bindDialogState(userListDialogState)}
                  title={t("Timeline.Likes")}
                  users={users}
                  headerLeftAction={<></>}
                  hasMoreUsers={hasMorePages}
                  fetchMoreUsers={fetchMore}
                  UserAction={FollowUserButton}
                />
              </>
            )}
          </div>
        </Grid>
        <Grid item className={classes.commentFooter}>
          <IconButton
            size="small"
            classes={{
              root: classes.button,
            }}
            onClick={isRestricted ? onRestrictedAction : handleOnLikeClick}>
            <TransitionedEntity>
              {t(`Timeline.${comment.likes.liked ? "Liked" : "Like"}`) as string}
            </TransitionedEntity>
          </IconButton>
          <IconButton
            size="small"
            classes={{ root: classes.button }}
            onClick={isRestricted ? onRestrictedAction : handleReplyClick}>
            {isNotAReply(comment) && comment.replies?.count && comment.replies?.count === 1
              ? `1 ${t("Timeline.ReplyCount.singular").toLowerCase()}`
              : isNotAReply(comment) && comment.replies?.count && comment.replies?.count > 1
              ? `${comment.replies?.count} ${t("Timeline.ReplyCount.plural").toLowerCase()}`
              : t("Timeline.Reply")}
          </IconButton>
          <span className={classes.date}>
            &bull;&nbsp;
            <Moment locale={i18n.language} fromNow>
              {getLocalDateFromUtc(comment.createdAt)}
            </Moment>
          </span>
          <Hide if={!loading} box className={classes.actionsSpinner}>
            <Spinner variant="extraSmall" />
          </Hide>
        </Grid>

        {isNotAReply(comment) && showReplies && (
          <Grid item className={classes.repliesContainer}>
            <CommentReplyList
              ref={postReplyListRef}
              parentComment={comment}
              isReadonly={isReadonly}
              onFollowableInteraction={onFollowableInteraction}
              onReadonlyInteraction={onReadonlyInteraction}
              onReplyClick={() => {}}
              postActivity={postActivity}
              hideDeleted={hideDeleted}
              highlightedReplyId={highlightedReplyId}
              handleReplyEdit={onEditReply}
              handleOnReply={handleOnReply}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
