import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  ForwardedRef,
  useEffect,
  useMemo,
  MouseEvent,
} from "react";

import {
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
  Theme,
  Tooltip,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import {
  AttachmentsForm,
  AttachmentsFormRefProps,
} from "@/attachments/components/attachments-form/AttachmentsForm";
import CustomSwitch from "@/common/components/CustomSwitch";
import Hide from "@/common/components/Hide";
import Spinner from "@/common/components/Spinner";
import { ReadOnlyProps } from "@/posts/components/PostCard";
import TextEditor, { TextEditorRefProps } from "@/text-editor/components/TextEditor";

import CrownBadgeIcon from "@/common/icons/CrownBadgeIcon";
import PlusIcon from "@/common/icons/PlusIcon";
import SendIcon from "@/common/icons/SendIcon";

import { useUploadsStore } from "@/attachments/hooks";
import { useAuthenticationStore } from "@/authentication/hooks";

import { AttachmentTypes } from "@/attachments/models";
import { CommentUnion } from "@/comments/types/CommentUnion";
import { findMentionsInContent } from "@/common/services/quillContents";
import { POST_MAX_LENGTH } from "@/posts/constants";

import useStyles from "./WriteComment.styles";

type WriteCommentProps = {
  variant: "comment" | "reply";
  onPostComment: (data: {
    comment: string;
    anonymous: boolean;
    mentions: string[];
    imageIds: string[];
    videoId?: string;
    fileIds: string[];
  }) => Promise<any>;
  comment?: CommentUnion;
  isRestricted?: boolean;
  onEscapeEdit?: () => any;
  onFollowableInteraction?: () => void;
  onRestrictedAction?: () => void;
} & ReadOnlyProps;

export type WriteCommentRefProps = {
  focus: () => void;
};

export const WriteComment = forwardRef<WriteCommentRefProps, WriteCommentProps>(
  (
    {
      variant,
      comment,
      onPostComment,
      onEscapeEdit,
      isReadonly,
      isRestricted,
      onFollowableInteraction,
      onRestrictedAction,
      onReadonlyInteraction,
    }: WriteCommentProps,
    ref: ForwardedRef<WriteCommentRefProps>,
  ) => {
    const { classes, cx } = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(comment?.body || "");
    const isEdit = !!comment;
    const {
      loading: loadingUploads,
      images,
      imageIds,
      files,
      fileIds,
      video,
      videoId,
      uploadsStatus,
      deleteImageAt,
      deleteVideo,
      deleteFileAt,
      uploadFiles,
      clearUploads,
    } = useUploadsStore({
      images: comment?.images?.edges.map(({ node }) => node) || [],
      video: comment?.video || undefined,
      files: comment?.files?.edges.map(({ node }) => node) || [],
    });
    const attachmentsForm = useRef<AttachmentsFormRefProps>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAnonymous, setIsAnonymous] = useState<boolean>(comment?.anonymous || false);
    const [showToolbar, setShowToolbar] = useState<boolean>(false);
    const { session } = useAuthenticationStore();
    const textEditorRef = useRef<TextEditorRefProps>(null);
    const [attachmentMenuAnchor, setAttachmentMenuAnchor] = useState<null | HTMLButtonElement>(
      null,
    );
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const hasSubscription = session && session.user && session.user.activeSubscription;

    useImperativeHandle(ref, () => ({
      focus: () => {
        textEditorRef?.current?.focus();
      },
    }));

    const getContentLength = (content: string) => {
      if (typeof window !== "undefined") {
        const el = document.createElement("html");

        el.innerHTML = content;

        return el.innerText?.trim().replace(/\n/g, "").length || 0;
      }

      return 0;
    };

    const commentContentLength = useMemo(() => getContentLength(value), [value]);

    const handleChange = (newValue: string) => {
      if (getContentLength(newValue) <= POST_MAX_LENGTH) {
        setValue(newValue);
      }
    };

    const handleSendComment = async () => {
      if (isReadonly) return onReadonlyInteraction && onReadonlyInteraction();
      setIsLoading(true);
      onFollowableInteraction && onFollowableInteraction();
      await onPostComment({
        comment: value,
        anonymous: isAnonymous,
        mentions: findMentionsInContent(value),
        imageIds,
        videoId,
        fileIds,
      });
      setIsLoading(false);
      textEditorRef.current?.clear();
      clearUploads();
    };

    const onInputFocus = () => {
      setShowToolbar(true);
    };

    const onInputClicked = () => {
      isReadonly && onReadonlyInteraction && onReadonlyInteraction();
    };

    const onAnonymousChanged = () => {
      if (!hasSubscription) return;
      setIsAnonymous((prevState) => !prevState);
    };

    const handleAttachmentMenuToggle = (event: MouseEvent<HTMLButtonElement>) => {
      setAttachmentMenuAnchor(attachmentMenuAnchor ? null : event.currentTarget);
    };

    const handleAttachmentMenuItemClick = (type: AttachmentTypes) => {
      attachmentsForm.current?.trigger(type);
    };

    const handleUpload = (files: File[], type?: AttachmentTypes) => uploadFiles(files, type);
    const stripMessageHTML = (v?: string) => {
      if (typeof window !== "undefined") {
        const el = document.createElement("html");

        if (v) {
          el.innerHTML = v;
        } else {
          el.innerHTML = value;
        }

        return el.innerText || "";
      }

      return "";
    };
    useEffect(() => {
      if (comment && comment.body) {
        textEditorRef.current?.setValue(stripMessageHTML(comment?.body));
        textEditorRef.current?.focus();
      } else {
        textEditorRef.current?.clear();
        textEditorRef.current?.blur();
      }
    }, [comment]);

    useEffect(() => {
      if (variant && variant === "reply") {
        textEditorRef.current?.focus();
      } else {
        textEditorRef.current?.clear();
        textEditorRef.current?.blur();
      }
    }, [variant]);

    useEffect(() => {
      if (textEditorRef && variant === "reply") {
        textEditorRef.current?.focus();
      }
    }, []);

    return (
      <>
        <Grid item xs={12}>
          <Hide if={!showToolbar}>
            <div className={classes.toolbar}>
              <Grid item container spacing={1} alignItems="center" justifyContent={"flex-end"}>
                <div>
                  <Tooltip
                    style={{ lineHeight: 0 }}
                    title={
                      (!hasSubscription ? t("Monetization.Generic.callToSubscribeTooltip") : "") ||
                      ""
                    }
                    arrow
                    placement="bottom">
                    <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                      <Grid item>
                        <Typography variant="body1" className={classes.isAnonymousLabel}>
                          <span>{t("Generic.PostAnonymously")}</span>
                          <CrownBadgeIcon blackBadge />
                        </Typography>
                      </Grid>
                      <Grid item pt={1}>
                        <CustomSwitch checked={isAnonymous} onChange={onAnonymousChanged} />
                      </Grid>
                    </div>
                  </Tooltip>
                </div>
              </Grid>
            </div>
          </Hide>
        </Grid>
        <Grid
          container
          wrap="nowrap"
          alignItems="flex-start"
          className={cx(classes.grid, classes[variant])}>
          <Grid item>
            <IconButton
              disabled={isLoading || loadingUploads || !!videoId}
              color="primary"
              onClick={isRestricted ? onRestrictedAction : handleAttachmentMenuToggle}
              classes={{
                root: classes.attachmentMenuButton,
              }}>
              <PlusIcon className={classes.attachmentMenuButtonIcon} />
              <Menu
                open={Boolean(attachmentMenuAnchor)}
                anchorEl={attachmentMenuAnchor}
                onClose={() => setAttachmentMenuAnchor(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}>
                {!(videoId || imageIds.length > 0) && (
                  <MenuItem
                    classes={{ root: classes.attachmentMenuItem }}
                    onClick={() => handleAttachmentMenuItemClick(AttachmentTypes.File)}>
                    {t("Attachments.AttachFile")}
                  </MenuItem>
                )}
                {!(fileIds.length > 0 || imageIds.length > 0) && (
                  <MenuItem
                    classes={{ root: classes.attachmentMenuItem }}
                    onClick={() => handleAttachmentMenuItemClick(AttachmentTypes.Video)}>
                    {t("Attachments.AttachVideo")}
                  </MenuItem>
                )}
                {!(videoId || fileIds.length > 0) && (
                  <MenuItem
                    classes={{ root: classes.attachmentMenuItem }}
                    onClick={() => handleAttachmentMenuItemClick(AttachmentTypes.Image)}>
                    {t("Attachments.AttachImage")}
                  </MenuItem>
                )}
              </Menu>
            </IconButton>
          </Grid>
          <Grid item container className={classes.inputRow}>
            <Grid item xs={12} className={classes.inputContainer}>
              <TextEditor
                ref={textEditorRef}
                className={cx(classes.input, classes.buttonInside)}
                value={comment?.body || ""}
                placeholder={t("Timeline.WriteAComment")}
                readonly={isReadonly}
                onChange={handleChange}
                onClick={onInputClicked}
                onEscape={onEscapeEdit}
                onFocus={onInputFocus}
                hideToolbar
              />
            </Grid>

            {isMobile || (
              <Grid item xs={12} classes={{ root: classes.attachmentsContainer }}>
                <AttachmentsForm
                  ref={attachmentsForm}
                  images={images}
                  video={video}
                  files={files}
                  thumbnailSize={isMobile ? "small" : "medium"}
                  uploadsStatus={uploadsStatus}
                  uploadFiles={handleUpload}
                  deleteImage={deleteImageAt}
                  deleteVideo={deleteVideo}
                  deleteFile={deleteFileAt}
                />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Hide if={isLoading}>
              <IconButton
                disabled={
                  !(
                    commentContentLength > 0 ||
                    videoId ||
                    imageIds.length > 0 ||
                    fileIds.length > 0
                  ) || loadingUploads
                }
                color="primary"
                onClick={isRestricted ? onRestrictedAction : handleSendComment}
                size="small"
                classes={{ root: classes.sendButton }}>
                <SendIcon className={classes.sendIcon} />
              </IconButton>
            </Hide>
            <Hide if={!isLoading}>
              <Spinner variant="small" />
            </Hide>
          </Grid>
        </Grid>
        {isMobile && (
          <div className={classes.attachmentsContainer}>
            <AttachmentsForm
              ref={attachmentsForm}
              images={images}
              video={video}
              files={files}
              thumbnailSize={isMobile ? "small" : "medium"}
              uploadsStatus={uploadsStatus}
              uploadFiles={handleUpload}
              deleteImage={deleteImageAt}
              deleteVideo={deleteVideo}
              deleteFile={deleteFileAt}
            />
          </div>
        )}
        <Hide if={true}>
          <Grid container>
            <Grid item>
              <div className={classes.avatar}></div>
            </Grid>
            <Grid item className={classes.inputContainer}>
              <Typography variant="body1" className={classes.hint}>
                {t("Timeline.PressEscToCancel")}
              </Typography>
            </Grid>
          </Grid>
        </Hide>
      </>
    );
  },
);

WriteComment.displayName = "WriteComment";
