import React, { useMemo, useRef, useState } from "react";

import { Dialog, Theme, Tooltip, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import ActivityCard from "@/common/components/ActivityCard";
import CustomSwitch from "@/common/components/CustomSwitch";
import UserAvatar from "@/common/components/UserAvatar";
import PostCard from "@/posts/components/PostCard";
import TextEditor, { TextEditorRefProps } from "@/text-editor/components/TextEditor";

import CrownBadgeIcon from "@/common/icons/CrownBadgeIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useProfilePostsStore } from "@/profile/hooks";

import { findMentionsInContent } from "@/common/services/quillContents";
import { SharedActivityItemUnion } from "@/common/types";
import { PostFragment, PostInput, SharedItemTypes } from "@/graphql/types";
import { POST_MAX_LENGTH } from "@/posts/constants";

import { Button } from "../button/Button";
import useStyles from "./ShareToProfileDialog.styles";
export type ShareToProfileDialogProps = {
  open: boolean;
  onClose: () => void;
  activity: SharedActivityItemUnion | PostFragment;
};

const ShareToProfileDialog = ({ open, activity, onClose }: ShareToProfileDialogProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const { session } = useAuthenticationStore();
  const { createPost } = useProfilePostsStore();

  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [postContent, setPostContent] = useState<string>("");

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const textEditorRef = useRef<TextEditorRefProps>(null);

  const handleContentChange = (content: string) => {
    setPostContent(content);
  };

  const postContentLength = useMemo(() => {
    if (typeof window !== "undefined") {
      const el = document.createElement("html");

      el.innerHTML = postContent;

      return el.innerText?.trim().replace(/\n/g, "").length || 0;
    }

    return 0;
  }, [postContent]);

  const onAnonymousChanged = () => {
    setIsAnonymous((prevState) => !prevState);
  };

  const sendPost = async () => {
    let sharedItemType = "";

    switch (activity.__typename) {
      case "Blog":
        sharedItemType = "BLOG";
        break;
      case "Group":
        sharedItemType = "GROUP";
        break;
      case "Event":
        sharedItemType = "EVENT";
        break;
      case "Petition":
        sharedItemType = "PETITION";
        break;
      case "Debate":
        sharedItemType = "DEBATE";
        break;
      case "LiveStream":
        sharedItemType = "LIVE_STREAM";
        break;
      case "Post":
        sharedItemType = "POST";
        break;
      default:
        break;
    }

    const data: PostInput = {
      anonymous: isAnonymous,
      body: postContent,
      mentionedUsersIds: findMentionsInContent(postContent),
      postTags: [],
      sharedItemId: activity.id,
      sharedItemType: sharedItemType as SharedItemTypes,
    };

    const result = await createPost({ data, id: session?.user?.id });

    if (result.data?.createPost?.successful) {
      setPostContent("");
      setIsAnonymous(false);
      onClose && onClose();
    }
  };

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        classes={{ paper: classes.dialog }}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        data-cy="ShareToProfileDialog">
        <div className={classes.dialogContainer}>
          <div className="header">
            <div className="left" onClick={() => onClose && onClose()}>
              {t("Generic.Cancel")}
            </div>
            <div className="center">
              {t("Common.ShareActivityButton.ShareOnProfileDialogTitle")}
            </div>
            <div className="right" onClick={sendPost}>
              <Button variant="text">{t("Profile.Post")}</Button>
            </div>
          </div>
          <div className="postForm">
            <div className="author">
              <div className="avatar">
                <UserAvatar user={session?.user} isAnonymous={isAnonymous} />
                {isAnonymous ? "Anonymous" : session?.user?.displayName}
              </div>
              <div className="anonymous">
                <Tooltip
                  style={{ lineHeight: 0 }}
                  title={
                    (!session?.user.activeSubscription
                      ? t("Monetization.Generic.callToSubscribeTooltip")
                      : "") || ""
                  }
                  arrow>
                  <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <CrownBadgeIcon blackBadge />
                    {t("Generic.PostAnonymously")}
                    <CustomSwitch
                      checked={isAnonymous}
                      onChange={() => {
                        if (!session?.user.activeSubscription) return;
                        onAnonymousChanged();
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="postTextarea">
              <TextEditor
                ref={textEditorRef}
                className={cx(classes.editor, {
                  [classes.editorLengthLimit]: postContentLength > POST_MAX_LENGTH,
                })}
                value={postContent}
                placeholder={t("Timeline.ShareWithOurCommunityPlaceholder")}
                onChange={handleContentChange}
                hideToolbar
              />
            </div>
          </div>
          <div className="preview">
            {activity.__typename === "Post" ? (
              <PostCard
                post={activity as PostFragment}
                showAdditionalInfo={false}
                isRestricted={true}
                hideComments={true}
              />
            ) : (
              <ActivityCard activity={activity as SharedActivityItemUnion} cardType="vertical" />
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ShareToProfileDialog;
