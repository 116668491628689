import {
  PostCommentFragment,
  UpdateCommentMutationVariables,
  DeleteCommentMutationVariables,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  usePostCommentQuery,
} from "@/graphql/types";

export const usePostsCommentStore = (
  commentId?: PostCommentFragment["id"],
  onCompleted?: () => void,
) => {
  const { data, loading: loadingComment } = usePostCommentQuery({
    variables: { id: commentId as string },
    skip: !commentId,
  });

  const [updateComment, { loading: updateLoading }] = useUpdateCommentMutation({
    onCompleted,
  });

  const [deleteComment, { loading: deleteLoading }] = useDeleteCommentMutation();

  return {
    comment: data?.comment,
    loading: updateLoading || deleteLoading,
    loadingComment,
    update({
      id,
      comment,
      anonymous,
      mentions,
      imageIds,
      videoId,
      fileIds,
    }: UpdateCommentMutationVariables) {
      return updateComment({
        variables: {
          id,
          comment,
          anonymous,
          mentions,
          imageIds,
          videoId,
          fileIds,
        },
      });
    },
    deleteComment({ id }: DeleteCommentMutationVariables) {
      return deleteComment({
        variables: {
          id,
        },
      });
    },
  };
};
