import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  ForwardedRef,
  useLayoutEffect,
} from "react";

import { Box, Grid } from "@mui/material";

import { CommentView } from "@/comments/components/CommentView/CommentView";
import { WriteCommentRefProps } from "@/comments/components/WriteComment/WriteComment";
import { ReadOnlyProps } from "@/posts/components/PostCard";

import { ActivityCardFragment, ActivityCardSlimFragment } from "@/common/models/ActivityModel";
import {
  PostCommentFragment,
  LiveStreamCommentFragment,
  CommentReplyFragment,
} from "@/graphql/types";

import { useCommentsLikeStore } from "../hooks/use-comments-like-store";
import { useCommentsReplyStore } from "../hooks/use-comments-reply-store";
import { isNotAReply } from "../services/comments";

type CommentReplyListProps = {
  parentComment: PostCommentFragment | LiveStreamCommentFragment;
  postActivity?: ActivityCardFragment | ActivityCardSlimFragment;
  onReplyClick?: () => void;
  onFollowableInteraction?: () => void;
  hideDeleted?: boolean;
  handleEdit?: () => void;
  handleReplyEdit: (reply: CommentReplyFragment) => void;
  handleOnReply?: (
    reply: CommentReplyFragment | PostCommentFragment,
    parentCommentId: string,
  ) => void;
  isRestricted?: boolean;
  highlightedReplyId?: string;
  onRestrictedAction?: () => void;
} & ReadOnlyProps;

type CommentReplyListRefProps = {
  focusWriteCommentField: () => void;
};

const CommentReply = ({
  reply,
  parentComment,
  isReadonly,
  onFollowableInteraction,
  onReadonlyInteraction,
  onReplyClick,
  handleReplyEdit,
  handleOnReply,
  postActivity,
  hideDeleted,
  highlightedReplyId,
  isRestricted,
  onRestrictedAction,
}: { reply: CommentReplyFragment } & CommentReplyListProps) => {
  const { deleteReply, loading } = useCommentsReplyStore(reply);
  const { toggleLike, toggleLikeLoading } = useCommentsLikeStore(reply);

  return (
    <CommentView
      key={reply.id}
      comment={reply}
      variant="reply"
      parentComment={parentComment}
      isReadonly={isReadonly}
      isRestricted={isRestricted}
      onFollowableInteraction={onFollowableInteraction}
      onReadonlyInteraction={onReadonlyInteraction}
      onRestrictedAction={onRestrictedAction}
      handleOnReply={(reply, parentCommentId) => {
        handleOnReply && handleOnReply(reply, parentCommentId);
      }}
      handleReplyEdit={(reply) => {
        handleReplyEdit(reply);
      }}
      postActivity={postActivity}
      handleDelete={deleteReply}
      handleLike={toggleLike}
      loading={loading || toggleLikeLoading}
      hideDeleted={hideDeleted}
      isHighlighted={highlightedReplyId === reply.id}
    />
  );
};

const CommentReplyList = (
  {
    parentComment,
    isReadonly,
    postActivity,
    onReadonlyInteraction,
    onReplyClick,
    onFollowableInteraction,
    handleOnReply,
    hideDeleted,
    highlightedReplyId,
    isRestricted,
    handleReplyEdit,
    onRestrictedAction,
  }: CommentReplyListProps,
  ref: ForwardedRef<CommentReplyListRefProps>,
) => {
  const writeCommentField = useRef<WriteCommentRefProps>(null);

  useImperativeHandle(ref, () => ({
    focusWriteCommentField: () => {
      writeCommentField?.current?.focus();
    },
  }));

  const replies = isNotAReply(parentComment) ? parentComment?.replies : null;

  useLayoutEffect(() => {
    const replyEl = document.getElementById(`comment_${highlightedReplyId}`);

    replyEl?.scrollIntoView();
  }, [highlightedReplyId]);

  return (
    <Box p={1.5} pb={0} pr={0} width="100%">
      <Grid container spacing={0}>
        {replies &&
          replies.edges?.map(
            (edge) =>
              edge?.node && (
                <CommentReply
                  key={edge.node.id}
                  reply={edge.node}
                  parentComment={parentComment}
                  isReadonly={isReadonly}
                  isRestricted={isRestricted}
                  onRestrictedAction={onRestrictedAction}
                  onFollowableInteraction={onFollowableInteraction}
                  onReadonlyInteraction={onReadonlyInteraction}
                  onReplyClick={() => {}}
                  postActivity={postActivity}
                  hideDeleted={hideDeleted}
                  highlightedReplyId={highlightedReplyId}
                  handleReplyEdit={handleReplyEdit}
                  handleOnReply={(reply) => {
                    handleOnReply && handleOnReply(reply, parentComment.id);
                  }}
                />
              ),
          )}
      </Grid>
      {/* 50 is the max number of replies we're currently fetching.
          If a need would arise, we'd have to implement pagination for replies,
          but as for now - we'll only allow max. 50 replies on a comment */}
      {/* {(replies?.count || 0) < 50 && (
        <WriteComment
          ref={writeCommentField}
          variant="reply"
          onPostComment={handleCommentReplyCreation}
          isReadonly={isReadonly}
          onReadonlyInteraction={onReadonlyInteraction}
        />
      )} */}
    </Box>
  );
};

export default forwardRef<CommentReplyListRefProps, CommentReplyListProps>(CommentReplyList);
