import React, { useEffect, useState, useContext } from "react";

import { Box, Grid, Link, useMediaQuery, Theme, CircularProgress, IconButton } from "@mui/material";

import { useTranslation } from "react-i18next";

import { WriteComment } from "@/comments/components/WriteComment/WriteComment";
import Select from "@/common/components/Select";
import { FeatureFlagOnly } from "@/feature-flags/components/FeatureFlagOnly";
import PostComment from "@/posts/components/post-comment/PostComment";
import { PostProps } from "@/posts/components/PostCard";

import CloseIcon from "@/common/icons/CloseIcon";

import { useCommentsStore } from "@/comments/hooks";
import { useCommentsReplyStore } from "@/comments/hooks/use-comments-reply-store";
import { usePostsCommentsStore, usePostsCommentStore } from "@/posts/hooks";
import { CommentsOrderOption } from "@/posts/hooks/use-posts-comments-store";

import { stripMessageHTML } from "@/common/services";
import {
  CommentPostMutationVariables,
  CommentReplyFragment,
  PostCommentFragment,
} from "@/graphql/types";
import { PostContext } from "@/posts/providers/PostProvider";

import useStyles from "./PostCommentList.styles";

const PostCommentList = ({
  post,
  isReadonly,
  onReadonlyInteraction,
  onFollowableInteraction,
  onRestrictedAction,
  postActivity,
  initialCommentsLimit = 1,
  highlightedCommentId,
  highlightedCommentReplyId,
  inViewport,
  isRestricted,
}: PostProps) => {
  const { cx, classes } = useStyles();
  const { t } = useTranslation();
  const [commentsOrder, setCommentsOrder] = useState<CommentsOrderOption>(
    CommentsOrderOption.MostRecent,
  );
  const [isReadyToFetch, setIsReadyToFetch] = useState<boolean>(false);
  const [commentToEdit, setCommentToEdit] = useState<
    PostCommentFragment | CommentReplyFragment | undefined
  >(undefined);
  const [commentToReply, setCommentToReply] = useState<
    PostCommentFragment | CommentReplyFragment | undefined
  >(undefined);
  const [parentCommentToReply, setParentCommentToReply] = useState<string | undefined>(undefined);
  const { postCommentsOutdated } = useContext(PostContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { comment: highlightedComment, update } = usePostsCommentStore(highlightedCommentId);
  const { update: updateReply } = useCommentsReplyStore();
  const { replyToComment } = useCommentsStore();

  const { fetchComments, refetch, create, loading, hasMorePages, comments, fetchNextCommentsPage } =
    usePostsCommentsStore(post);

  const commentsOrderOptions = [
    {
      id: CommentsOrderOption.MostRecent,
      name: t("Comments.CommentsOrderOptions.MostRecent"),
    },
    {
      id: CommentsOrderOption.AllComments,
      name: t("Comments.CommentsOrderOptions.AllComments"),
    },
  ];

  const handleCommentCreation = async ({
    comment,
    anonymous,
    mentions,
    imageIds,
    videoId,
    fileIds,
  }: Omit<CommentPostMutationVariables, "sortBy" | "limit" | "postId">) => {
    onFollowableInteraction && onFollowableInteraction();
    if (commentToReply) {
      await replyToComment({
        commentId: parentCommentToReply || commentToReply.id,
        comment,
        anonymous,
        mentions,
        imageIds,
        videoId,
        fileIds,
      });
      setCommentToReply(undefined);

      return Promise.resolve();
    }
    if (commentToEdit) {
      const highlightedElements = document.getElementsByClassName("editedHighlight");

      for (var i = 0; i < highlightedElements.length; i++) {
        highlightedElements[i].classList.remove("editedHighlight");
      }

      if (commentToEdit.__typename === "Comment") {
        const res = await update({
          id: commentToEdit.id,
          comment,
          mentions,
          imageIds,
          videoId,
          fileIds,
          anonymous,
        });

        if (res.data?.updateComment?.successful) {
          const el = document.getElementById(`comment_${commentToEdit.id}_editing`);
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "center" });
            el.id = `comment_${commentToEdit.id}`;
            el.classList.add("editedHighlight");
          }
        }
      } else if (commentToEdit.__typename === "CommentReply") {
        const res = await updateReply({
          id: commentToEdit.id,
          comment,
          mentions,
          imageIds,
          videoId,
          fileIds,
          anonymous,
        });

        if (res.data?.updateCommentReply?.successful) {
          const el = document.getElementById(`comment_${commentToEdit.id}_editing`);
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "center" });
            el.id = `comment_${commentToEdit.id}`;
            el.classList.add("editedHighlight");
          }
        }
      }
      setCommentToEdit(undefined);

      return Promise.resolve();
    }

    return create({
      comment,
      anonymous,
      mentions,
      imageIds,
      videoId,
      fileIds,
    });
  };

  const onEditComment = (comment: PostCommentFragment | CommentReplyFragment) => {
    setCommentToReply(undefined);

    setCommentToEdit(comment);
  };
  const onReplyToComment = (comment: PostCommentFragment | CommentReplyFragment, id: string) => {
    setCommentToReply(undefined);
    setCommentToEdit(undefined);

    setParentCommentToReply(id || undefined);
    setCommentToReply(comment);
  };

  useEffect(() => {
    if (inViewport) {
      if (isReadyToFetch && postCommentsOutdated && comments.length === 0) {
        fetchComments({ order: commentsOrder, limit: initialCommentsLimit });
      }
      setIsReadyToFetch(true);
    }
  }, [inViewport]);

  useEffect(() => {
    if (isReadyToFetch) {
      if (isMobile) {
        fetchComments({ order: commentsOrder });
      } else {
        fetchComments({ order: commentsOrder, limit: initialCommentsLimit });
      }
    }
  }, [commentsOrder, initialCommentsLimit, isReadyToFetch]);

  return (
    <div className={cx(classes.container, { [classes.noComments]: !post.commentsCounter })}>
      {!!post.commentsCounter && (
        <>
          <FeatureFlagOnly forceEnabled flag="FE_COMMENTS_ORDER_OPTIONS">
            <div className={classes.commentsOrderWrapper}>
              <Select
                classes={{
                  select: classes.orderSelect,
                  icon: classes.orderSelect,
                }}
                value={commentsOrder}
                onChange={(event) => setCommentsOrder(event.target.value as CommentsOrderOption)}
                options={commentsOrderOptions}
              />
            </div>
          </FeatureFlagOnly>
          {postCommentsOutdated && (
            <div className={classes.loadMoreWrapper}>
              <Link
                classes={{ root: classes.seeMoreLink }}
                onClick={() => refetch()}
                underline="hover">
                {t("Comments.LoadMore")}
              </Link>
            </div>
          )}
          <Box className={classes.mainContainer}>
            <Grid container spacing={0} gap={"21px"}>
              {highlightedComment && (
                <PostComment
                  comment={highlightedComment}
                  isReadonly={isReadonly}
                  isRestricted={isRestricted}
                  onReadonlyInteraction={onReadonlyInteraction}
                  postActivity={postActivity}
                  onFollowableInteraction={onFollowableInteraction}
                  isHighlighted
                  highlightedReplyId={highlightedCommentReplyId}
                  onRestrictedAction={onRestrictedAction}
                  onEditComment={onEditComment}
                />
              )}
              {comments.map((comment) =>
                comment.id !== highlightedCommentId ? (
                  <PostComment
                    key={comment.id}
                    comment={comment}
                    isReadonly={isReadonly}
                    isRestricted={isRestricted}
                    onReadonlyInteraction={onReadonlyInteraction}
                    postActivity={postActivity}
                    onFollowableInteraction={onFollowableInteraction}
                    onRestrictedAction={onRestrictedAction}
                    onEditComment={onEditComment}
                    onReplyToComment={onReplyToComment}
                  />
                ) : null,
              )}
            </Grid>
            {(loading || hasMorePages) && (
              <div className={classes.seeMoreContainer}>
                {loading ? (
                  <CircularProgress size={"21px"} />
                ) : (
                  hasMorePages && (
                    <Link
                      classes={{ root: classes.seeMoreLink }}
                      onClick={() => fetchNextCommentsPage({})}
                      underline="hover">
                      {t("Comments.LoadMore")}
                    </Link>
                  )
                )}
              </div>
            )}
          </Box>
        </>
      )}
      {!isMobile && (
        <div className={classes.writeCommentContainer}>
          {commentToEdit && (
            <div className={classes.editingMessageHeader}>
              <span>{t("Timeline.EditingMessage")}</span>
              <IconButton onClick={() => setCommentToEdit(undefined)} size="small">
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {commentToReply && (
            <div>
              <div className={classes.replyingMessageContainer}>
                <div className={cx(classes.editingMessageHeader, "noBorder")}>
                  <span>
                    {t("App.Comments.ReplyingTo")} {commentToReply.author?.displayName}
                  </span>
                  <IconButton onClick={() => setCommentToReply(undefined)} size="small">
                    <CloseIcon />
                  </IconButton>
                </div>
                {commentToReply.body && (
                  <span className="preview">{stripMessageHTML(commentToReply.body)}</span>
                )}
              </div>
            </div>
          )}

          <WriteComment
            variant={commentToReply ? "reply" : "comment"}
            onPostComment={handleCommentCreation}
            isReadonly={isReadonly}
            isRestricted={isRestricted}
            onReadonlyInteraction={onReadonlyInteraction}
            onRestrictedAction={onRestrictedAction}
            comment={commentToEdit}
          />
        </div>
      )}
      {isMobile && (
        <div>
          {commentToEdit && (
            <div className={classes.editingMessageHeader}>
              <span>{t("Timeline.EditingMessage")}</span>
              <IconButton onClick={() => setCommentToEdit(undefined)} size="small">
                <CloseIcon />
              </IconButton>
            </div>
          )}
          {commentToReply && (
            <div>
              <div className={classes.replyingMessageContainer}>
                <div className={cx(classes.editingMessageHeader, "noBorder")}>
                  {t("App.Comments.ReplyingTo")} {commentToReply.author?.displayName}
                  <IconButton onClick={() => setCommentToReply(undefined)} size="small">
                    <CloseIcon />
                  </IconButton>
                </div>
                {commentToReply.body && (
                  <span className="preview">{stripMessageHTML(commentToReply.body)}</span>
                )}
              </div>
            </div>
          )}
          <WriteComment
            variant={commentToReply ? "reply" : "comment"}
            onPostComment={handleCommentCreation}
            isReadonly={isReadonly}
            isRestricted={isRestricted}
            onReadonlyInteraction={onReadonlyInteraction}
            onRestrictedAction={onRestrictedAction}
            comment={commentToEdit}
          />
        </div>
      )}
    </div>
  );
};

export default PostCommentList;
