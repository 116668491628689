import { useState, useEffect } from "react";

import { Box, SwipeableDrawer, SwipeableDrawerProps, Typography } from "@mui/material";

import useStyles from "./PostCommentsDrawer.styles";

export type PostCommentsDrawerProps = Omit<SwipeableDrawerProps, "onOpen"> & {
  children: React.ReactNode;
  drawerTitle: string;
};

export const PostCommentsDrawer = ({
  open,
  drawerTitle,
  onClose,
  children,
}: PostCommentsDrawerProps) => {
  const { classes } = useStyles();
  const [container, setContainer] = useState<HTMLElement>();

  useEffect(() => {
    setContainer(window.document.body);
  }, []);

  return (
    <SwipeableDrawer
      container={container}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: false,
      }}
      className={classes.commentsDrawer}
      classes={{ paper: classes.commentsDrawerPaper }}>
      <Box className={classes.commentsDrawerHeader}>
        <Box className={classes.puller} />
        <Typography className={classes.drawerTitle}>{drawerTitle}</Typography>
      </Box>
      <Box className={classes.drawerContent}>{children}</Box>
    </SwipeableDrawer>
  );
};
