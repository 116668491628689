import type { StringMap } from "quill";

export const POST_MAX_LENGTH = 20000;

export const MENTION_MAX_PROPOSALS = 5;

export const DEFAULT_QUILL_FORMATS = ["link", "mention"];

// All the formats except image and video
export const QUILL_FORMATS = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "mention",
  "cuaddsEmoji",
];

// All the formats except video
export const QUILL_BLOG_ARTICLE_FORMATS = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "mention",
  "cuaddsEmoji",
  "image",
  "preview",
];

export const QUILL_BLOG_MODULES: StringMap = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"],
    ["link", "image"],
    [{ script: "sub" }, { script: "super" }],
    [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["blockquote", "code-block"],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    ["clean"],
  ],
  blotFormatter: {},
};

export const ALLOWED_USERNAME_CHARS = /^[A-z0-9_]+$/;
