import { useRouter } from "next/router";
import { useState, useEffect, useCallback } from "react";

import { PostFragment } from "@/graphql/types";

/**
 * Hook for handling the post url, returning the required data and automatically handling scrolling to the post
 *
 * @param post Post of the current post cardx
 */
export const useHandlePostUrl = (post: Pick<PostFragment, "id">) => {
  const router = useRouter();
  const [element, setElement] = useState<HTMLDivElement>();

  const isCurrentPost = router.query.post_id?.toString() === post.id;

  const handleElement = useCallback((node: HTMLDivElement) => {
    setElement(node);
  }, []);

  useEffect(() => {
    if (router.query.post_id?.toString() === post.id.toString()) {
      element?.scrollIntoView();
    }
  }, [router.query.post_id, element]);

  return {
    isCurrentPost,
    handleElement,
  };
};
