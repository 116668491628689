import React, { useState } from "react";

import { Card, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import ActivityCard from "@/common/components/ActivityCard";
import PostCard, { PostProps } from "@/posts/components/PostCard";
import { PostCardContent } from "@/posts/components/PostCardContent/PostCardContent";
import PostCardHeader from "@/posts/components/PostCardHeader";
import PostReactionBar from "@/posts/components/PostReactionBar";

import { useViewportIntersectionMonitor } from "@/common/hooks/use-viewport-intersection-monitor";
import { usePostStore } from "@/posts/hooks";

import { SharedActivityItemUnion } from "@/common/types";
import { PostFragment } from "@/graphql/types";

import { useHandlePostUrl } from "../services/postUrl";
import PostCommentList from "./post-comments-list/PostCommentList";
import { PostEmbeds } from "./PostEmbeds";

const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    backgroundColor: theme.mode.background.light,
    overflow: "visible",
    boxShadow: "0 0 16px 0 rgba(63, 61, 86, 0.08)",
  },
  dividerComment: {
    [theme.breakpoints.up("md")]: {
      borderTop: "2px solid " + theme.mode.background.comment,
      marginBottom: 16,
    },
  },
  sharedItemContainer: {
    borderTop: "2px solid" + theme.mode.background.comment,
    borderBottom: "2px solid" + theme.mode.background.comment,

    '& div[class$="verticalCard"]': {
      boxShadow: "none",
    },
  },
}));
interface PostCardViewProps extends PostProps {
  onEditClicked?: (post: PostFragment) => void;
  onEditTriggered?: (post: PostFragment) => void;
}
function PostCardView({
  post,
  showAdditionalInfo,
  inCommunityFeed,
  postActivity,
  isReadonly,
  onFollowableInteraction,
  onReadonlyInteraction,
  onEditTriggered,
  onEditClicked,
  initialCommentsLimit,
  highlightedCommentId,
  highlightedCommentReplyId,
  timelinePinnable,
  isRestricted,
  hideComments,
  onRestrictedAction,
}: PostCardViewProps) {
  const { classes } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [isInViewport, setIsInViewport] = useState<boolean>(false);
  const { handleElement } = useViewportIntersectionMonitor({
    options: {
      rootMargin: "-100px",
    },
    timeout: 400,
    enter: () => {
      setIsInViewport(true);
    },
    leave: () => {
      setIsInViewport(false);
    },
  });
  const { postUpdateLoading } = usePostStore({
    post,
    inViewport: isInViewport,
  });
  const { handleElement: handlePostUrlElement } = useHandlePostUrl(post);

  const handleRef = (node: HTMLDivElement) => {
    handleElement(node);
    handlePostUrlElement(node);
  };

  return (
    <Card classes={{ root: classes.card }} className="Postcard" data-cy="PostCard" ref={handleRef}>
      <PostCardHeader
        post={post}
        onEditTriggered={onEditTriggered}
        onEditClicked={onEditClicked}
        showAdditionalInfo={showAdditionalInfo}
        postActivity={postActivity}
        timelinePinnable={timelinePinnable}
        isRestricted={isRestricted}
      />
      <PostCardContent post={post} loading={postUpdateLoading} />
      {post.embeds && <PostEmbeds embeds={post.embeds.edges.map((edge) => edge.node)} />}
      {post.sharedItem && (
        <div className={classes.sharedItemContainer}>
          {post.sharedItem.__typename === "Post" ? (
            <div ref={handleRef}>
              <PostCard
                post={post.sharedItem as PostFragment}
                showAdditionalInfo={false}
                isRestricted={false}
                hideComments={true}
              />
            </div>
          ) : (
            <ActivityCard
              activity={post.sharedItem as SharedActivityItemUnion}
              cardType="vertical"
              pinnable={false}
            />
          )}
        </div>
      )}
      <PostReactionBar
        post={post}
        inCommunityFeed={inCommunityFeed}
        isReadonly={isReadonly}
        isRestricted={isRestricted}
        postActivity={postActivity}
        onFollowableInteraction={onFollowableInteraction}
        onReadonlyInteraction={onReadonlyInteraction}
        onRestrictedAction={onRestrictedAction}
      />

      {!isMobile && !hideComments && (
        <PostCommentList
          post={post}
          isReadonly={isReadonly}
          onFollowableInteraction={onFollowableInteraction}
          onReadonlyInteraction={onReadonlyInteraction}
          postActivity={postActivity}
          initialCommentsLimit={initialCommentsLimit}
          highlightedCommentId={highlightedCommentId}
          highlightedCommentReplyId={highlightedCommentReplyId}
          inViewport={isInViewport}
          isRestricted={isRestricted}
          onRestrictedAction={onRestrictedAction}
        />
      )}
    </Card>
  );
}
export default PostCardView;
