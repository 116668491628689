import dynamic from "next/dynamic";
import React from "react";

import { Facebook, Twitter } from "@mui/icons-material";
import { Grid, IconButton, Menu, MenuItem, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { SITE_URL } from "@config/env";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";
import { Subject } from "rxjs";

import type { ChatRoomShareDialogProps } from "@/chat/components/ChatRoomShareDialog/ChatRoomShareDialog";
import { Button } from "@/common/components/button/Button";
import { PostCommentsDrawer } from "@/common/components/PostCommentsDrawer/PostCommentsDrawer";
import ShareToProfileDialog from "@/common/components/share-to-profile/ShareToProfileDialog";
import { TransitionedEntity } from "@/common/components/TransitionedEntity/TransitionedEntity";
import { UserListModal } from "@/common/components/UserListModal/UserListModal";
import PostCommentList from "@/posts/components/post-comments-list/PostCommentList";
import { PostProps } from "@/posts/components/PostCard";

import LinkIcon from "@/common/icons/about/LinkIcon";
import ChatIcon from "@/common/icons/ChatIcon";
import CommentIcon from "@/common/icons/CommentIcon";
import HeartIcon from "@/common/icons/HeartIcon";
import NewMessageIcon from "@/common/icons/NewMessageIcon";
import ShareIcon from "@/common/icons/ShareIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useChatsStore } from "@/chat/hooks";
import { bindDialogState, useDialog, useUnsubscribe } from "@/common/hooks";
import { useProfileFollowersStore } from "@/profile/hooks";

import { UserListEntity } from "@/common/models/UserListEntity";
import { useSnackbarContext } from "@/common/providers";
import { copyToClipboard } from "@/common/services";
import { ChatInput, UserBaseFragment, useSendMessageMutation } from "@/graphql/types";

import { usePostLikesStore } from "../hooks";
import { getFacebookIntent, getTwitterIntent, openShareWindow } from "../services/share";

const ChatRoomShareDialog = dynamic<ChatRoomShareDialogProps>(
  () =>
    import("@/chat/components/ChatRoomShareDialog/ChatRoomShareDialog").then(
      (module) => module.ChatRoomShareDialog,
    ),
  {
    ssr: false,
  },
);

const unsubscribe = new Subject();

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(0, 2, 2, 2),
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5, 1.5, 1.5),
    },
  },
  iconButton: {
    padding: 0,
    color: theme.mode.text.description,
  },
  iconButtonLiked: {
    color: theme.palette.primary.main,
  },
  icon: {
    width: 24,
    height: 24,
    fill: theme.mode.icon.light,
    color: theme.mode.icon.light,
    marginLeft: 2,
  },
  iconBig: {
    width: 32,
    height: 32,
  },
  iconSelected: {
    fill: theme.palette.primary.main,
  },
  iconReadonly: {
    fill: "#e4e4e4",
  },
  shareMenuItem: {
    color: theme.mode.text.heading,
  },
  shareMenuIcon: {
    marginRight: theme.spacing(1.5),
    marginLeft: 0,
  },
  commentsIndicator: {
    display: "flex",
    alignItems: "center",
    fill: theme.mode.icon.light,
    color: theme.mode.text.description,
  },
  indicatorLabel: {
    fontSize: "0.8125rem",
    fontWeight: "bold",
    color: theme.mode.text.subtitle,
  },
  followActionText: {
    backgroundColor: theme.mode.background.light,
    fontSize: "1.0625rem",
    fontWeight: 800,
    padding: theme.spacing(0.75, 1.5),
    margin: theme.spacing(-0.75, -1.5),
    lineHeight: "normal",
    minHeight: "auto",
    borderRadius: "9px",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.mode.background.default,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.9375rem",
      zIndex: 10,
    },
  },
}));

export const FollowUserButton = ({ user }: { user: UserListEntity }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { toggleFollow, loadingToggleFollow } = useProfileFollowersStore({
    user: user as UserBaseFragment,
  });

  if (user.isMe) {
    return null;
  }

  return (
    <Button
      className={classes.followActionText}
      variant={
        user.follows?.isFollowedByMe || loadingToggleFollow ? "inline-secondary" : "inline-primary"
      }
      onClick={toggleFollow}
      disabled={loadingToggleFollow}>
      {loadingToggleFollow ? (
        user.follows?.isFollowedByMe ? (
          `${t("Generic.Unfollowing").toUpperCase()}...`
        ) : (
          `${t("Generic.Following").toUpperCase()}...`
        )
      ) : (
        <span>
          {user.follows?.isFollowedByMe
            ? t("Discover.UnfollowText").toUpperCase()
            : t("Discover.FollowText").toUpperCase()}
        </span>
      )}
    </Button>
  );
};

interface PostReactionBarProps extends PostProps {
  onFollowableInteraction?: () => void;
}

function PostReactionBar({
  post,
  inCommunityFeed,
  isReadonly,
  isRestricted,
  postActivity,
  highlightedCommentId,
  highlightedCommentReplyId,
  onFollowableInteraction,
  onReadonlyInteraction,
  onRestrictedAction,
}: PostReactionBarProps) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  useUnsubscribe(unsubscribe);
  const userListDialogState = useDialog();
  const commentListDrawerState = useDialog();
  const { users, hasMorePages, toggleLikeLoading, fetch, fetchMore, toggleLike } =
    usePostLikesStore(post);
  const sharePopupState = usePopupState({ variant: "popover", popupId: "postMenu" });
  const { session } = useAuthenticationStore();
  const { open: openSnackbar } = useSnackbarContext();
  const ChatRoomShareDialogState = useDialog();
  const { createChat } = useChatsStore();
  const [sendMessage, { loading: loadingSendMessage }] = useSendMessageMutation();
  const shareToProfileDialog = useDialog();

  const fullPostURL = SITE_URL + post.url;

  const handleLikeClick = () => {
    if (isReadonly) return onReadonlyInteraction && onReadonlyInteraction();
    if (toggleLikeLoading) return;

    onFollowableInteraction && onFollowableInteraction();
    toggleLike();
  };

  const handleLikesCountClick = () => {
    fetch();
    userListDialogState.open();
  };

  const handleCommentsCountClick = () => {
    if (!isMobile) return;
    commentListDrawerState.open();
  };

  const handleCopyURL = () => {
    copyToClipboard(fullPostURL);
    sharePopupState.close();
    openSnackbar({
      open: true,
      message: t("Common.SharePost.CopyLinkSuccess"),
    });
  };

  const handleChatShare = () => {
    sharePopupState.close();
    ChatRoomShareDialogState.open();
  };

  const handleChatShareSend = async (data: ChatInput) => {
    if (data) {
      const response = await createChat(data);
      if (response.data?.createChat?.id) {
        await sendMessage({
          variables: {
            data: {
              chatId: response.data?.createChat?.id,
              body: `<a href="${decodeURIComponent(
                fullPostURL,
              )}" rel="noopener noreferrer" target="_blank">${decodeURIComponent(fullPostURL)}</a>`,
            },
          },
        });
        ChatRoomShareDialogState.close();
      }
    }
  };

  const handleProfileShare = () => {
    if (session?.user) {
      shareToProfileDialog.open();
    }
    sharePopupState.close();
  };

  const handleFacebookShare = () => {
    openShareWindow(getFacebookIntent(fullPostURL));
    sharePopupState.close();
  };

  const handleTwitterShare = () => {
    openShareWindow(getTwitterIntent(t("Common.SharePost.Message.Twitter"), fullPostURL));
    sharePopupState.close();
  };
  const commentLabel = t(
    `Timeline.${post.commentsCounter || 0 > 1 ? "Comments" : "Comment"}`,
  ).toLowerCase() as string;

  const handleRestrictedAction = () => {
    onRestrictedAction && onRestrictedAction();
  };

  const shareItems = [
    {
      action: handleCopyURL,
      label: t("Common.SharePost.CopyLink"),
      IconComponent: LinkIcon,
    },
    {
      action: isRestricted ? handleRestrictedAction : handleChatShare,
      label: t("Common.SharePost.ShareOnChat"),
      IconComponent: ChatIcon,
    },
    {
      action: isRestricted ? handleRestrictedAction : handleProfileShare,
      label: t("Common.SharePost.ShareOnProfile"),
      IconComponent: NewMessageIcon,
    },
    {
      action: handleFacebookShare,
      label: t("Common.SharePost.ShareOnFacebook"),
      IconComponent: Facebook,
    },
    {
      action: handleTwitterShare,
      label: t("Common.SharePost.ShareOnTwitter"),
      IconComponent: Twitter,
    },
  ];

  return (
    <Grid
      container
      direction={isMobile ? "column" : "row"}
      wrap="nowrap"
      spacing={1}
      className={classes.container}>
      <Grid item container spacing={2}>
        <Grid item>
          <IconButton
            classes={{
              root: cx(classes.iconButton, post.likes?.liked && classes.iconButtonLiked),
            }}
            size="small"
            color={post.likes?.liked ? "primary" : "default"}
            onClick={isRestricted ? handleRestrictedAction : handleLikeClick}>
            <HeartIcon
              className={cx(classes.icon, {
                [classes.iconSelected]: post.likes?.liked,
                [classes.iconReadonly]: isReadonly,
                [classes.iconBig]: inCommunityFeed,
              })}
            />
          </IconButton>
        </Grid>
        {isMobile && (
          <Grid item>
            <IconButton
              classes={{
                root: classes.iconButton,
              }}
              size="small"
              color="default"
              onClick={isRestricted ? handleRestrictedAction : handleCommentsCountClick}>
              <CommentIcon className={cx(classes.icon, { [classes.iconBig]: inCommunityFeed })} />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <IconButton
            classes={{ root: classes.iconButton }}
            size="small"
            {...bindTrigger(sharePopupState)}>
            <ShareIcon
              className={cx(classes.icon, {
                [classes.iconReadonly]: isReadonly,
                [classes.iconBig]: inCommunityFeed,
              })}
            />
          </IconButton>

          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            {...bindMenu(sharePopupState)}>
            {shareItems.map(({ action, label, IconComponent }, i) => (
              <MenuItem key={i} className={classes.shareMenuItem} onClick={action}>
                <IconComponent className={cx(classes.icon, classes.shareMenuIcon)} />
                {label}
              </MenuItem>
            ))}
          </Menu>

          <ChatRoomShareDialog
            {...bindDialogState(ChatRoomShareDialogState)}
            onSubmit={handleChatShareSend}
            loading={loadingSendMessage}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" justifyContent={isMobile ? "flex-start" : "flex-end"}>
        {post.likes?.likesCount > 0 && (
          <Grid item>
            <Button
              variant="formless"
              className={classes.indicatorLabel}
              onClick={handleLikesCountClick}>
              <TransitionedEntity>{post.likes.likesCount}</TransitionedEntity>
              &nbsp;
              <TransitionedEntity>
                {
                  t(
                    `Timeline.${post.likes.likesCount > 1 ? "Likes" : "Like"}`,
                  ).toLowerCase() as string
                }
              </TransitionedEntity>
            </Button>

            <UserListModal
              open={userListDialogState.internalState.open}
              onClose={() => userListDialogState.close()}
              title={t("Timeline.Likes")}
              users={users}
              headerLeftAction={<></>}
              hasMoreUsers={hasMorePages}
              fetchMoreUsers={fetchMore}
              UserAction={FollowUserButton}
            />
          </Grid>
        )}
        {!!post.commentsCounter && (
          <Grid item>
            <span className={classes.indicatorLabel} onClick={handleCommentsCountClick}>
              {post.likes?.likesCount > 0 && <span>&nbsp;-&nbsp;</span>}
              <TransitionedEntity>{post.commentsCounter}</TransitionedEntity>
              &nbsp;
              <TransitionedEntity>{commentLabel}</TransitionedEntity>
            </span>
          </Grid>
        )}
        <PostCommentsDrawer
          drawerTitle={`${post.commentsCounter} ${commentLabel}`}
          open={commentListDrawerState.internalState.open}
          onClose={() => commentListDrawerState.close()}>
          <PostCommentList
            post={post}
            isReadonly={isReadonly}
            onFollowableInteraction={onFollowableInteraction}
            onReadonlyInteraction={onReadonlyInteraction}
            postActivity={postActivity}
            highlightedCommentId={highlightedCommentId}
            highlightedCommentReplyId={highlightedCommentReplyId}
            inViewport={true}
          />
        </PostCommentsDrawer>
      </Grid>
      <ShareToProfileDialog {...bindDialogState(shareToProfileDialog)} activity={post} />
    </Grid>
  );
}

export default PostReactionBar;
