import dynamic from "next/dynamic";
import React from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Grid, IconButton, Menu, MenuItem, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";

import type { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";
import type { ReportAbuseDialogProps } from "@/common/components/ReportAbuseDialog";
import { PostProps } from "@/posts/components/PostCard";

import CrownBadgeIcon from "@/common/icons/CrownBadgeIcon";
import PinnedPostIcon from "@/common/icons/PinnedPostIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { bindDialogState, useDialog } from "@/common/hooks";
import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";
import { useGroupsStore } from "@/groups/hooks/use-groups-store";
import { usePostsReportsStore, usePostsStore } from "@/posts/hooks";
import { useProfileTimelineStore } from "@/profile/hooks/use-profile-timeline-store";

import { ReportActivities } from "@/common/models/Enums";
import { ReportReason } from "@/common/models/Report";
import {
  PostFragment,
  useDeletePostMutation,
  UserTimelineItemType,
  GroupTimelineItemType,
} from "@/graphql/types";

const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);
const ReportAbuseDialog = dynamic<ReportAbuseDialogProps>(
  () => import("@/common/components/ReportAbuseDialog").then((module) => module.ReportAbuseDialog),
  {
    ssr: false,
  },
);

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    padding: 0,
  },
  mr: {
    marginRight: theme.spacing(1.5),
  },
}));

export interface PostActionsProps extends PostProps {
  onEditClicked?: (post: PostFragment) => void;
  onEditTriggered?: (post: PostFragment) => void;
}

function PostCardActions({ post, postActivity, onEditClicked }: PostActionsProps) {
  const { cx, classes } = useStyles();
  const popupState = usePopupState({ variant: "popover", popupId: "postMenu" });
  const { t } = useTranslation();
  const { session } = useAuthenticationStore();
  const user = session?.user;
  const isDebatePost = postActivity?.__typename === "Debate";

  const canDelete =
    user?.id === post.author?.id || post.isAuthoredByMe || postActivity?.author?.id === user?.id;
  const canEdit = user?.id === post.author?.id || post.isAuthoredByMe;
  const canReport = user?.id !== post.author?.id && !post.isAuthoredByMe;
  const canPin =
    user?.id === postActivity?.author?.id ||
    (!post.activity?.id && !post.otherUserProfile?.id && post.author?.id === user?.id) ||
    post.otherUserProfile?.id === user?.id;

  const confirmDialogState = useConfirmDialog();
  const dialogState = useDialog<ReportReason>();
  const { report } = usePostsReportsStore(post);

  const [deletePost] = useDeletePostMutation({
    variables: {
      id: post.id,
    },
    update(cache) {
      const normalizedId = cache.identify({ id: post.id, __typename: "Post" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  function handleReport() {
    dialogState.open();
    popupState.close();
  }

  function handleEdit() {
    if (!user?.activeSubscription) {
      return;
    }
    onEditClicked && onEditClicked(post);
    popupState.close();
  }

  function confirmDelete() {
    popupState.close();
    confirmDialogState.open({
      title: isDebatePost ? t("Timeline.DeleteComment") : t("Timeline.DeletePost"),
      message: isDebatePost
        ? t("Timeline.DebatesAreYouSureYouWantToDeleteComment")
        : t("Timeline.AreYouSureYouWantToDeletePost"),
      onConfirm: () => {
        deletePost();
      },
    });
  }

  const { triggerPin } = usePostsStore();
  const { triggerItemPin } = useProfileTimelineStore();
  const { triggerGroupItemPin } = useGroupsStore();
  const handlePin = (type: UserTimelineItemType, groupType: GroupTimelineItemType) => {
    if (
      (!post.activity?.id && !post.otherUserProfile?.id && post.author?.id === user?.id) ||
      post.otherUserProfile?.id === user?.id
    ) {
      triggerItemPin(post.id, type);
    } else if (post.activity?.__typename === "Group") {
      triggerGroupItemPin(post.id, groupType);
    } else {
      triggerPin(post.id);
    }
    popupState.close();
  };

  return (
    <>
      <ConfirmDialog dialogState={confirmDialogState} />
      <ReportAbuseDialog
        {...bindDialogState(dialogState)}
        type={ReportActivities.genericActivity}
        onReport={report}
        user={post.author}
      />
      <Grid container justifyContent="flex-end">
        {post.pinned && (
          <IconButton
            size="small"
            onClick={() => handlePin(UserTimelineItemType.Post, GroupTimelineItemType.Post)}
            className={cx(classes.button, classes.mr)}>
            <PinnedPostIcon />
          </IconButton>
        )}
        <IconButton
          size="small"
          className={classes.button}
          {...bindTrigger(popupState)}
          data-cy="PostCardActions__toggle-menu">
          <MoreHorizIcon />
        </IconButton>

        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          {...bindMenu(popupState)}>
          {canPin && (
            <MenuItem
              onClick={() => handlePin(UserTimelineItemType.Post, GroupTimelineItemType.Post)}>
              {!post.pinned ? t("Timeline.PinToTop") : t("Timeline.Unpin")}
            </MenuItem>
          )}
          {canReport && (
            <MenuItem onClick={handleReport} data-cy="PostCardActions__report">
              {isDebatePost ? t("Timeline.ReportComment") : t("Timeline.ReportPost")}
            </MenuItem>
          )}
          {canEdit && (
            <MenuItem onClick={handleEdit} data-cy="PostCardActions__edit">
              <Tooltip
                style={{ display: "flex", marginTop: 5 }}
                title={
                  (user && !user.activeSubscription
                    ? t("Monetization.Generic.callToSubscribeTooltip")
                    : "") || ""
                }
                arrow
                placement="right">
                <div>
                  <span>
                    {isDebatePost ? t("Timeline.DebatesEditComment") : t("Timeline.EditPost")}
                  </span>
                  <div style={{ marginLeft: 15, marginTop: 2 }}>
                    <CrownBadgeIcon blackBadge />
                  </div>
                </div>
              </Tooltip>
            </MenuItem>
          )}
          {canDelete && (
            <MenuItem onClick={confirmDelete} data-cy="PostCardActions__delete">
              {isDebatePost ? t("Timeline.DebatesDeleteComment") : t("Timeline.DeletePost")}
            </MenuItem>
          )}
        </Menu>
      </Grid>
    </>
  );
}

export default PostCardActions;
