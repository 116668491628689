import { PostFragment, useReportPostMutation } from "@/graphql/types";

export const usePostsReportsStore = (post: PostFragment) => {
  const [report] = useReportPostMutation();

  return {
    report({ type, comment }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: post.id,
            type,
            comment,
          },
        }).then(({ data }: any) => {
          if (data?.reportPost?.successful) {
            resolve(data?.reportPost?.result);
          } else {
            reject(data?.reportPost);
          }
        });
      });
    },
  };
};
