import {
  Likeable,
  useGetLikedByLazyQuery,
  useLikeCommentMutation,
  useUnlikeCommentMutation,
} from "@/graphql/types";

import { CommentUnion } from "../types/CommentUnion";

export const useCommentsLikeStore = (comment: CommentUnion) => {
  const [fetch, { loading: loadingLikedBy, data, fetchMore }] = useGetLikedByLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [like, { loading: likeLoading }] = useLikeCommentMutation({
    variables: { id: comment.id },
  });
  const [unlike, { loading: unlikeLoading }] = useUnlikeCommentMutation({
    variables: { id: comment.id },
  });

  return {
    loadingLikedBy,
    toggleLikeLoading: likeLoading || unlikeLoading,
    users: data?.likedBy?.edges?.map(({ node }) => node) || [],
    hasMorePages: data?.likedBy?.pageInfo.hasNextPage || false,
    fetch() {
      return fetch({ variables: { id: comment.id, limit: 10, type: Likeable.Comment } });
    },
    fetchMore() {
      if (fetchMore && data?.likedBy) {
        return fetchMore({
          variables: {
            after: data.likedBy.pageInfo.endCursor,
            limit: 10,
          },
        });
      }
    },
    toggleLike: () => {
      if (!comment.likes.liked) {
        return like();
      }

      return unlike();
    },
  };
};
