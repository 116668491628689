import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  dialog: {
    maxWidth: "612px",
    width: "100%",
    backgroundColor: theme.mode.background.light,

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },

  editor: {
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "unset",
    },
    "& .ql-editor": {
      padding: theme.spacing(2),
      height: 160,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1.5),
      },
    },
    "& .ql-container": {
      fontSize: 16,
      borderRadius: "1rem",
      backgroundColor: theme.mode.background.input,
      border: "none",
      color: theme.mode.text.heading,
    },
    "& .ql-blank::before": {
      fontSize: "0.8125rem",
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "normal",
      color: "#a9a9b2",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8125rem",
      },
    },
  },
  editorLengthLimit: {
    "& .ql-container": {
      border: "3px solid red",
    },
  },
  dialogContainer: {
    "& .header": {
      display: "flex",
      padding: "32px",
      justifyContent: "space-between",
      borderBottom: "2px solid #e4e4e4",
      "& .left": {
        color: theme.mode.icon.light,
        fontSize: "1.0625rem",
        fontWeight: "800",
        lineHeight: "1.29",
        cursor: "pointer",
      },

      "& .center": {
        fontSize: "1.0625rem",
        color: theme.mode.text.heading,
        fontWeight: "800",
        lineHeight: "1.29",
      },

      "& .right": {
        color: theme.mode.text.heading,

        "& button": {
          fontSize: "1.0625rem",
          fontWeight: "800",
          lineHeight: "1.29",
          cursor: "pointer",
          padding: "0 !important",
          minHeight: "unset !important",
          textTransform: "uppercase",
        },
      },
    },

    "& .postForm": {
      padding: "32px",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      "& .author": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: "auto",
        "& .avatar, & .anonymous": {
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          gap: "8px",
          color: theme.mode.text.heading,
          fontWeight: "bold",
          lineHeight: "1.6",
          fontSize: "0.9375rem",
        },
        "& .anonymous": {
          gap: "12px",
          color: theme.mode.text.formLabel,
          lineHeight: "1.23",
          fontSize: "0.8125rem",
        },
      },
    },
    "& .preview": {
      padding: "32px",
      borderTop: "2px solid #e4e4e4",
    },
  },
}));

export default useStyles;
