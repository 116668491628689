import dynamic from "next/dynamic";
import React, { useState } from "react";

import { ImageGrid } from "@/attachments/components/image-grid/ImageGrid";
import { ImagesCarousel } from "@/attachments/components/ImagesCarousel/ImagesCarousel";
import type { MediaViewerDialogProps } from "@/attachments/components/media-viewer-dialog/MediaViewerDialog";
import { VideoPlayer } from "@/attachments/components/video-player/VideoPlayer";

import FileIcon from "@/common/icons/FileIcon";

import { bindDialogState, useDialog } from "@/common/hooks";

import { getImageUrls, getFormattedFiles } from "@/attachments/services";
import { getAnimatedPosterFromURL } from "@/common/services";
import {
  ImageDataFragment,
  FileDataFragment,
  VideoDataFragment,
  UserBaseFragment,
} from "@/graphql/types";

import useStyles from "./Attachments.styles";

const MediaViewerDialog = dynamic<MediaViewerDialogProps>(
  () =>
    import("@/attachments/components/media-viewer-dialog/MediaViewerDialog").then(
      (module) => module.MediaViewerDialog,
    ),
  {
    ssr: false,
  },
);

type AttachmentsProps = {
  images: {
    count: number;
    edges: Array<{ node: ImageDataFragment }>;
  };
  files: {
    count: number;
    edges: Array<{ node: FileDataFragment }>;
  };
  video?: VideoDataFragment | null;
  author?: UserBaseFragment | null;
  imagesVariant?: "carousel" | "grid";
  classes?: { imageCarousel?: string; files?: string };
};

export const Attachments = ({
  images,
  files,
  video,
  author,
  imagesVariant,
  classes: classNames,
}: AttachmentsProps) => {
  const { classes } = useStyles();
  const isPoliticAll = author?.username.toLowerCase() === "politicall";
  const mediaViewerDialogState = useDialog();
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(0);
  const toggleMediaViewerDialog = (index: number) => {
    setSelectedPhotoIndex(index);
    mediaViewerDialogState.open();
  };

  return images?.count > 0 || files?.count > 0 || video ? (
    <div className={classes.wrapper}>
      {images?.count > 0 && (
        <>
          {imagesVariant === "carousel" ? (
            <ImagesCarousel
              className={classNames?.imageCarousel}
              imageUrls={getImageUrls(images)}
              onClick={(index: number) => toggleMediaViewerDialog(index)}
            />
          ) : (
            <ImageGrid
              imageUrls={getImageUrls(images)}
              onClick={(index: number) => toggleMediaViewerDialog(index)}
            />
          )}
          <MediaViewerDialog
            media={getImageUrls(images)}
            selectedIndex={selectedPhotoIndex}
            {...bindDialogState(mediaViewerDialogState)}
          />
        </>
      )}
      {files?.count > 0 && (
        <ul className={classNames?.files}>
          {getFormattedFiles(files).map(({ id, url, name }) => (
            <li key={id} data-cy="Attachments__file">
              <a className={classes.file} href={url} download={name}>
                <FileIcon className={classes.fileIcon} />
                <span className={classes.fileName}>{name}</span>
              </a>
            </li>
          ))}
        </ul>
      )}
      {video && (
        <div className={classes.playerWrapper} data-cy="Attachments__video">
          <VideoPlayer
            src={video.url || undefined}
            poster={getAnimatedPosterFromURL(video.url || undefined, isPoliticAll)}
            className={classes.video}
          />
        </div>
      )}
    </div>
  ) : null;
};
