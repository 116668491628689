import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "sendIcon" | "buttonInside">()(
  (theme: Theme, _params, classes) => ({
    inputRow: {
      flexGrow: 1,
      width: "100%",
    },
    inputContainer: {
      position: "relative",
    },
    input: {
      position: "relative",

      "& .ql-container": {
        display: "flex",
        alignItems: "center",
        minHeight: 40,
        boxSizing: "border-box",
        borderRadius: 32,
        padding: theme.spacing(1.25, 1.5),
        [theme.breakpoints.down("sm")]: {
          minHeight: 34,
          borderRadius: 16,
          padding: theme.spacing(0.5, 1.5),
        },
        font: "inherit",
        backgroundColor: theme.mode.background.default,
        color: theme.mode.text.heading,
        border: "none",
        fontSize: 16,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.13)",
          cursor: "text",
        },
        "&:focus-within": {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
        },
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      },

      "& .ql-editor": {
        height: "auto",
        boxSizing: "border-box",
        font: "inherit",
        padding: `0px`,
        maxHeight: "130px",
        width: "100%",
      },
      "& .ql-blank::before": {
        left: theme.spacing(1.5),
        fontStyle: "normal",
        color: "#a9a9b2",
        whiteSpace: "nowrap",
      },
      [`&.${classes.buttonInside} .ql-container`]: {
        paddingRight: theme.spacing(5.25),
      },
    },
    buttonInside: {},
    progressInline: {
      position: "absolute",
      right: theme.spacing(1),
      top: "calc(50% - 11px)",
    },
    sendIcon: {
      width: 40,
      height: 40,
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        width: 32,
        height: 32,
      },
    },
    sendButton: {
      padding: 0,
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: 1,
      },
      [`&:disabled > .${classes.sendIcon}`]: {
        fill: theme.mode.icon.backgroundLight,
        color: theme.mode.icon.backgroundLight,
      },
    },
    isAnonymousLabel: {
      color: theme.mode.text.formLabel,
      fontSize: "0.9375rem",
      fontWeight: "bold",
      display: "flex",
      justifyItems: "center",
      flexDirection: "row-reverse",
      gap: 8,
      marginTop: 8,
      "& span": {
        paddingTop: 3,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.625rem",
      },
    },
    grid: {
      gap: theme.spacing(1.5),
      padding: "0px 16px",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "40px minmax(40px, 100%) 40px",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "24px minmax(24px, 100%) 34px",
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    comment: {},
    reply: {},
    avatar: {
      width: 40,
      height: 40,
      [theme.breakpoints.down("sm")]: {
        width: 24,
        height: 24,
        marginTop: 5,
      },
    },
    anonymousControl: {
      gap: theme.spacing(1.5),
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        gap: theme.spacing(1),
        padding: theme.spacing(0.5, 0),
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      padding: 16,
      paddingTop: 0,
    },
    attachmentMenuItem: {
      "& > *": {
        fill: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
    attachmentMenuButton: {
      padding: theme.spacing(0.75),
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
      marginTop: -4,
      "&:disabled": {
        fill: "#BDBDBD",
        color: theme.mode.icon.backgroundLight,
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1.25, 0),
      },
    },
    attachmentMenuButtonIcon: {
      width: 32,
      height: 32,
    },
    attachmentsContainer: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
      },
    },
    hint: {
      marginLeft: 20,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }),
);

export default useStyles;
