import React from "react";

import { IconButton, useMediaQuery, Theme } from "@mui/material";

import { Carousel } from "react-responsive-carousel";

import ArrowIcon from "@/common/icons/ArrowIcon";

import useStyles from "./ImagesCarousel.styles";

type ImagesCarouselProps = {
  imageUrls: string[];
  className?: string;
  onClick: (index: number) => void;
};

export const ImagesCarousel = ({ imageUrls = [], className, onClick }: ImagesCarouselProps) => {
  const { classes, cx } = useStyles();
  const isSingleImage = imageUrls.length == 1;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const renderNavArrow = (handler: () => void, direction: "right" | "left") => (
    <IconButton
      classes={{ root: classes.arrowButton }}
      className={direction}
      onClick={handler}
      size="large">
      <ArrowIcon direction={direction} width="32" height="32" />
    </IconButton>
  );

  return (
    <Carousel
      className={cx(classes.imagesCarousel, className, {
        [classes.singleImageCarouselContainer]: isSingleImage,
      })}
      centerMode
      centerSlidePercentage={imageUrls.length > 1 ? 80 : 100}
      selectedItem={imageUrls.length > 2 ? 1 : 0}
      showThumbs={false}
      useKeyboardArrows={false}
      showStatus={false}
      showIndicators={false}
      renderArrowPrev={(clickHandler: () => void, hasPrev: boolean) =>
        isMobile || (hasPrev && renderNavArrow(clickHandler, "left"))
      }
      renderArrowNext={(clickHandler: () => void, hasNext: boolean) =>
        isMobile || (hasNext && renderNavArrow(clickHandler, "right"))
      }
      onClickItem={(index) => onClick(index)}>
      {imageUrls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt="Image"
          data-cy="ImagesCarousel__image"
          className={cx(classes.imagesCarouselImage, {
            [classes.singleImage]: isSingleImage,
          })}
        />
      ))}
    </Carousel>
  );
};
