import { ApolloCache } from "@apollo/client";

import {
  PostFragment,
  Likeable,
  PostFragmentDoc,
  useGetLikedByLazyQuery,
  useLikePostMutation,
  useUnlikePostMutation,
} from "@/graphql/types";

export const usePostLikesStore = (post: PostFragment) => {
  const [fetch, { loading: loadingLikedBy, data, fetchMore }] = useGetLikedByLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const toggleLikeUpdate = (cache: ApolloCache<any>, likes: PostFragment["likes"]) => {
    const result = cache.readFragment({
      id: `${post.__typename}:${post.id}`,
      fragment: PostFragmentDoc,
      fragmentName: "Post",
    }) as PostFragment;

    if (result) {
      cache.writeFragment({
        id: `${post.__typename}:${post.id}`,
        fragment: PostFragmentDoc,
        fragmentName: "Post",
        data: {
          ...result,
          likes: {
            ...result.likes,
            ...likes,
          },
        },
      });
    }
  };

  const [likePost, { loading: likeLoading }] = useLikePostMutation({
    variables: {
      id: post.id,
    },
    update: (cache, { data }) => {
      if (!data?.likePost?.result) return;
      toggleLikeUpdate(cache, data.likePost.result.likes);
    },
  });
  const [unlikePost, { loading: unlikeLoading }] = useUnlikePostMutation({
    variables: {
      id: post.id,
    },
    update: (cache, { data }) => {
      if (!data?.unlikePost?.result) return;
      toggleLikeUpdate(cache, data.unlikePost.result.likes);
    },
  });

  return {
    loadingLikedBy,
    toggleLikeLoading: likeLoading || unlikeLoading,
    users: data?.likedBy?.edges?.map(({ node }) => node) || [],
    hasMorePages: data?.likedBy?.pageInfo.hasNextPage || false,
    fetch() {
      return fetch({ variables: { id: post.id, limit: 10, type: Likeable.Post } });
    },
    fetchMore() {
      if (fetchMore && data?.likedBy) {
        return fetchMore({
          variables: {
            after: data.likedBy.pageInfo.endCursor,
            limit: 10,
          },
        });
      }
    },
    toggleLike() {
      if (!post.likes?.liked) {
        return likePost();
      }
      return unlikePost();
    },
  };
};
