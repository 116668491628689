import { ReplyToCommentMutationVariables, useReplyToCommentMutation } from "@/graphql/types";

export const useCommentsStore = () => {
  const [replyToComment] = useReplyToCommentMutation();

  return {
    replyToComment({
      commentId,
      comment,
      anonymous,
      mentions,
      imageIds,
      videoId,
      fileIds,
    }: Pick<
      ReplyToCommentMutationVariables,
      "commentId" | "comment" | "anonymous" | "mentions" | "imageIds" | "videoId" | "fileIds"
    >) {
      return replyToComment({
        variables: { comment, commentId, anonymous, mentions, imageIds, videoId, fileIds },
      });
    },
  };
};
