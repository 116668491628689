import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1, 0, 2, 1),
    borderTop: "2px solid black",
    borderTopColor: theme.mode.background.comment,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5, 1.5, 1.5),
    },
  },
  noComments: {
    padding: theme.spacing(0, 0, 2, 0),
    borderTop: "unset",
  },
  seeMoreContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing("16px", 0),
    alignItems: "center",
  },
  seeMoreLink: {
    fontSize: "0.9375rem",
    fontWeight: 800,
    lineHeight: "1.47",
  },
  mainContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: 16,
    paddingRight: 16,
  },
  commentsOrderWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 16,
  },
  orderSelect: {
    color: `${theme.mode.text.heading} !important`,
    fontSize: "0.8125rem",
    fontWeight: "800 !important",
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  loadMoreWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1.5, 0),
  },
  writeCommentContainer: {
    borderTop: "2px solid black",
    borderTopColor: theme.mode.background.comment,
    paddingTop: 16,
    marginLeft: -8,
  },
  editingMessageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 15px 8px 26px",
    marginBottom: "16px",
    borderBottom: "2px solid",
    borderBottomColor: theme.mode.background.comment,
    marginLeft: "-10px",
    marginTop: "-5px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      paddingTop: "12px",
      marginLeft: 0,
      borderTop: "2px solid",
      borderTopColor: theme.mode.background.comment,
      paddingLeft: 16,
      paddingRight: 16,
    },

    "& span": {
      fontSize: "0.8125rem",
      fontWeight: 600,
      lineHeight: 1.23,
      color: theme.mode.text.description,
    },

    "& svg": {
      height: 20,
      width: 20,
    },
  },
  replyingMessageContainer: {
    "& .noBorder": {
      borderBottom: "none",
    },
    "& .preview": {
      padding: "0px 22px 0px 18px",
      marginTop: -20,
      color: theme.mode.text.formLabel,
      fontSize: "0.9375rem",
      lineHeight: 1.6,
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 16,
      },
    },
    borderBottom: "2px solid",
    borderBottomColor: theme.mode.background.comment,
    marginBottom: "16px",
    paddingBottom: "12px",
  },
}));
