import React from "react";

import PostCardView from "@/posts/components/PostCardView";

import { ActivityCardFragment, ActivityCardSlimFragment } from "@/common/models/ActivityModel";
import { ActivityType } from "@/common/models/Enums";
import { PostFragment } from "@/graphql/types";
import PostProvider from "@/posts/providers/PostProvider";
export interface ReadOnlyProps {
  isReadonly?: boolean;
  onReadonlyInteraction?: () => void;
}

export interface PostProps extends ReadOnlyProps {
  post: PostFragment;
  refresh?: number;
  showAdditionalInfo?: boolean;
  inCommunityFeed?: boolean;
  postActivity?: ActivityCardFragment | ActivityCardSlimFragment;
  onFollowableInteraction?: () => void;
  initialCommentsLimit?: number;
  highlightedCommentId?: string;
  highlightedCommentReplyId?: string;
  timelinePinnable?: boolean;
  inViewport?: boolean;
  isRestricted?: boolean;
  hideComments?: boolean;
  onRestrictedAction?: () => void;
}

export interface PostCardProps extends PostProps {
  onPostDeleted?: (post: PostFragment) => void;
  onPostUpdated?: (post: PostFragment) => void;
  onPostEdited?: (post: PostFragment) => void;
  onEditClicked?: (post: PostFragment) => void;
  activityType?: ActivityType;
}

function PostCard({
  post,
  showAdditionalInfo,
  inCommunityFeed,
  postActivity,
  onEditClicked,
  isReadonly,
  onFollowableInteraction,
  onReadonlyInteraction,
  initialCommentsLimit,
  highlightedCommentId,
  highlightedCommentReplyId,
  timelinePinnable,
  isRestricted,
  hideComments,
  onRestrictedAction,
}: PostCardProps) {
  return (
    <PostProvider>
      <PostCardView
        onEditClicked={onEditClicked}
        post={post}
        isReadonly={isReadonly}
        onReadonlyInteraction={onReadonlyInteraction}
        onFollowableInteraction={onFollowableInteraction}
        showAdditionalInfo={showAdditionalInfo}
        postActivity={postActivity}
        initialCommentsLimit={initialCommentsLimit}
        highlightedCommentId={highlightedCommentId}
        highlightedCommentReplyId={highlightedCommentReplyId}
        timelinePinnable={timelinePinnable}
        isRestricted={isRestricted}
        onRestrictedAction={onRestrictedAction}
        inCommunityFeed={inCommunityFeed}
        hideComments={hideComments}
      />
    </PostProvider>
  );
}

export default PostCard;
