import { UpdatePostInput, useTriggerPostPinMutation, useUpdatePostMutation } from "@/graphql/types";

export const usePostsStore = () => {
  const [updatePost] = useUpdatePostMutation();
  const [triggerPin] = useTriggerPostPinMutation();

  return {
    updatePost(postId: string, postData: UpdatePostInput) {
      return updatePost({
        variables: {
          id: postId,
          data: postData,
        },
      });
    },
    triggerPin(postId: string) {
      return triggerPin({
        variables: {
          id: postId,
        },
        refetchQueries: [
          "GetUserPosts",
          "GetBlogPosts",
          "GetEventPosts",
          "GetGroupPosts",
          "GetPetitionPosts",
          "ProfileTimeline",
        ],
      });
    },
  };
};
