import { useEffect, useContext } from "react";

import { useApolloClient, useSubscription } from "@apollo/client";

import {
  PostChangedDocument,
  PostFragment,
  PostFragmentDoc,
  useGetPostLazyQuery,
  GetPostQuery,
} from "@/graphql/types";
import { PostContext } from "@/posts/providers/PostProvider";

type PostStoreProps = { post: PostFragment; inViewport: boolean };

export const usePostStore = ({ post, inViewport }: PostStoreProps) => {
  const apolloClient = useApolloClient();

  const { ignoreNewCommentUpdates, setPostOutdated, setPostCommentsOutdated } =
    useContext(PostContext);

  const [fetchPost, { loading: postUpdateLoading }] = useGetPostLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { id: post.id },
    onCompleted(data: GetPostQuery) {
      if (!data.post) return;

      setPostOutdated(false);

      const { body, images, files, video, updatedAt } = data.post;
      const result = apolloClient.cache.readFragment({
        id: `${post.__typename}:${post.id}`,
        fragment: PostFragmentDoc,
        fragmentName: "Post",
      }) as PostFragment;

      if (result) {
        apolloClient.cache.writeFragment({
          id: `${post.__typename}:${post.id}`,
          fragment: PostFragmentDoc,
          fragmentName: "Post",
          data: {
            ...result,
            body,
            images,
            files,
            video,
            updatedAt,
          },
        });
      }
    },
  });
  const { data: postChangedData } = useSubscription(PostChangedDocument, {
    variables: {
      postId: post.id,
    },
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data.postChanged || ignoreNewCommentUpdates) return;
      const { likes, commentsCounter, updatedAt } = subscriptionData.data.postChanged;

      setPostOutdated(updatedAt !== post.updatedAt);
      setPostCommentsOutdated(commentsCounter !== post.commentsCounter);

      const result = client.cache.readFragment({
        id: `${post.__typename}:${post.id}`,
        fragment: PostFragmentDoc,
        fragmentName: "Post",
      }) as PostFragment;

      if (result) {
        client.cache.writeFragment({
          id: `${post.__typename}:${post.id}`,
          fragment: PostFragmentDoc,
          fragmentName: "Post",
          data: {
            ...result,
            likes: {
              ...result.likes,
              ...likes,
            },
            commentsCounter,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (
      postChangedData?.postChanged &&
      postChangedData.postChanged.updatedAt !== post.updatedAt &&
      inViewport
    ) {
      fetchPost();
    }
  }, [postChangedData?.postChanged?.updatedAt, inViewport]);

  return {
    postUpdateLoading,
  };
};
