import { useContext } from "react";

import { useSubscription } from "@apollo/client";

import {
  PostFragment,
  CommentsOrder,
  CommentPostMutationVariables,
  PostCommentChangedDocument,
  PostCommentFragment,
  PostCommentFragmentDoc,
  useGetPostCommentsLazyQuery,
  useCommentPostMutation,
} from "@/graphql/types";
import { PostContext } from "@/posts/providers/PostProvider";

export enum CommentsOrderOption {
  MostRecent,
  AllComments,
}
const CommentsOrderOptionToOrder = {
  [CommentsOrderOption.MostRecent]: CommentsOrder.NewestFirst,
  [CommentsOrderOption.AllComments]: CommentsOrder.OldestFirst,
} as const;

export const usePostsCommentsStore = (post: PostFragment) => {
  const { setPostCommentsOutdated, setIgnoreNewCommentUpdates } = useContext(PostContext);
  const [fetchComments, { data, networkStatus, fetchMore, refetch, variables }] =
    useGetPostCommentsLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
      onCompleted() {
        setPostCommentsOutdated(false);
      },
    });

  const [createComment] = useCommentPostMutation();

  useSubscription(PostCommentChangedDocument, {
    fetchPolicy: "no-cache",
    variables: {
      postId: post.id,
    },
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data.postCommentChanged) return;
      const { likes, id } = subscriptionData.data.postCommentChanged;
      const result = client.cache.readFragment({
        id: `Comment:${id}`,
        fragment: PostCommentFragmentDoc,
        fragmentName: "PostComment",
      }) as PostCommentFragment;

      if (result) {
        client.cache.writeFragment({
          id: `Comment:${id}`,
          fragment: PostCommentFragmentDoc,
          fragmentName: "PostComment",
          data: {
            ...result,
            likes: {
              ...result.likes,
              ...likes,
            },
          },
        });
      }
    },
  });

  return {
    comments: data?.post?.comments?.edges.map(({ node }) => node) || [],
    loading: [1, 3].includes(networkStatus),
    hasMorePages: data?.post?.comments?.pageInfo.hasNextPage || false,
    fetchComments({ order, limit = 10 }: { order: CommentsOrderOption; limit?: number }) {
      return fetchComments({
        variables: {
          postId: post.id,
          limit,
          sortBy: CommentsOrderOptionToOrder[order],
        },
      });
    },
    refetch() {
      return (
        refetch &&
        refetch({
          limit: (data?.post?.comments.edges.map(({ node }) => node) || []).length + 1,
        })
      );
    },
    fetchNextCommentsPage({ limit = 10 }: { limit?: number }) {
      if (fetchMore && data?.post) {
        return fetchMore({
          variables: {
            after: data.post.comments.pageInfo.endCursor,
            limit,
          },
        });
      }
    },
    create({
      comment,
      anonymous,
      mentions,
      imageIds,
      videoId,
      fileIds,
    }: Omit<CommentPostMutationVariables, "sortBy" | "limit" | "postId">) {
      setIgnoreNewCommentUpdates(true);
      return createComment({
        variables: {
          postId: post.id,
          anonymous,
          comment,
          mentions,
          imageIds,
          videoId,
          fileIds,
          limit: (data?.post?.comments.edges.map(({ node }) => node) || []).length + 1,
          sortBy: variables?.sortBy || CommentsOrderOptionToOrder[CommentsOrderOption.MostRecent],
        },
        update() {
          setIgnoreNewCommentUpdates(false);
        },
        onError() {
          setIgnoreNewCommentUpdates(false);
        },
      });
    },
  };
};
