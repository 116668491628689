import {
  CommentReplyFragment,
  UpdateCommentReplyMutationVariables,
  useDeleteCommentReplyMutation,
  useUpdateCommentReplyMutation,
} from "@/graphql/types";

export const useCommentsReplyStore = (comment?: CommentReplyFragment, onCompleted?: () => void) => {
  const [updateReply, { loading: updateLoading }] = useUpdateCommentReplyMutation({
    onCompleted,
  });

  const [deleteReply, { loading: deleteLoading }] = useDeleteCommentReplyMutation({
    variables: {
      id: comment?.id || "0",
    },
  });

  return {
    update({
      id,
      comment: commentBody,
      anonymous,
      mentions,
      imageIds,
      videoId,
      fileIds,
    }: Pick<
      UpdateCommentReplyMutationVariables,
      "id" | "comment" | "anonymous" | "mentions" | "imageIds" | "videoId" | "fileIds"
    >) {
      return updateReply({
        variables: {
          id: id,
          comment: commentBody,
          anonymous,
          mentions,
          imageIds,
          videoId,
          fileIds,
        },
      });
    },
    deleteReply() {
      return deleteReply();
    },
    loading: updateLoading || deleteLoading,
  };
};
