import { useEffect, useState } from "react";

import { findLinksInContent } from "@/common/services/quillContents";
import { PostFragment, useGetEmbedsLazyQuery } from "@/graphql/types";

type Link = {
  url: string;
  isEmbed: boolean;
};

const EMBEDS_LIMIT = 3;

export const usePostsEmbedsStore = (existingPost?: PostFragment) => {
  const [fetchEmbeds, { data, loading }] = useGetEmbedsLazyQuery();
  const [links, setLinks] = useState<Link[]>([]);

  const updateLinks = (postContent: string) => {
    if (postContent) {
      const foundLinks = findLinksInContent(postContent);

      setLinks((prev) => {
        return foundLinks.reduce<Link[]>((acc, curr) => {
          const linkWithUrl = prev.find((link) => link.url === curr);

          if (linkWithUrl) {
            acc.push(linkWithUrl);
          } else if (acc.filter((el) => el.isEmbed).length < EMBEDS_LIMIT) {
            acc.push({
              url: curr,
              isEmbed: true,
            });
          }

          return acc;
        }, [] as Link[]);
      });
    }
  };

  const handleEmbedRemove = (url: string, postContent: string) => {
    setLinks((prev) =>
      prev.reduce((acc, curr) => {
        if (curr.url === url) {
          acc.push({ ...curr, isEmbed: false });
        } else {
          acc.push(curr);
        }

        return acc;
      }, [] as Link[]),
    );

    updateLinks(postContent);
  };

  const handleEmbedsContentChange = (updatedContent: string) => {
    updateLinks(updatedContent);
  };

  const embedUrls = links.reduce((acc, curr) => {
    if (curr.isEmbed) {
      acc.push(curr.url);
    }
    return acc;
  }, [] as string[]);

  useEffect(() => {
    fetchEmbeds({
      variables: {
        urls: embedUrls,
      },
    });
  }, [JSON.stringify(links)]);

  useEffect(() => {
    if (existingPost?.body && existingPost?.embeds.edges) {
      try {
        const existingLinks = findLinksInContent(existingPost.body);

        setLinks(
          existingLinks.reduce((acc, curr) => {
            if (existingPost.embeds.edges.find((el) => el.node.url === curr)) {
              acc.push({ url: curr, isEmbed: true });
            } else {
              acc.push({ url: curr, isEmbed: false });
            }
            return acc;
          }, [] as Link[]),
        );
      } catch {
        updateLinks(existingPost?.body);
      }
    }
  }, [existingPost?.body, JSON.stringify(existingPost?.embeds)]);

  return {
    fetchEmbeds,
    embeds: data?.embeds || [],
    embedUrls,
    loading,
    updateLinks,
    handleEmbedRemove,
    handleEmbedsContentChange,
  };
};
