import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles<void, "replySection" | "bodyWrapper">()(
  (theme: Theme, _params, classes) => ({
    section: {
      marginBottom: theme.spacing(1.5),
      [`&.${classes.replySection} .${classes.bodyWrapper}`]: {
        [theme.breakpoints.down("sm")]: {
          minWidth: 164,
        },
      },
    },
    commentSection: {
      marginBottom: 0,
      "&.editedHighlight .toHighlight": {
        "@keyframes highlightAnimation": {
          "0%": {
            backgroundColor: darken(theme.mode.background.commentHighlighted, 0.5),
          },
          "100%": {
            backgroundColor: theme.mode.background.comment,
          },
        },
        animation: "highlightAnimation 1000ms ease-out",
      },
    },
    replySection: {
      "&.editedHighlight .toHighlight": {
        "@keyframes highlightAnimation": {
          "0%": {
            backgroundColor: darken(theme.mode.background.commentHighlighted, 0.5),
          },
          "100%": {
            backgroundColor: theme.mode.background.comment,
          },
        },
        animation: "highlightAnimation 1000ms ease-out",
      },
    },
    commentFooter: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.5),
      display: "flex",
      alignItems: "center",
    },
    commentAttachments: {
      marginLeft: theme.spacing(2),
    },
    commentContainer: {
      display: "flex",
      maxWidth: "100%",
    },
    repliesContainer: {
      marginLeft: 0,
      width: "100%",
    },
    button: {
      padding: 0,
      marginRight: "16px",
      fontSize: "0.8125rem",
      fontWeight: "bold",
      lineHeight: "1.85",
      height: "auto",
      color: theme.mode.text.formLabel,
    },
    date: {
      display: "flex",
      gap: 8,
      fontSize: "0.6875rem",
      fontWeight: 600,
      color: "#bebec5",
    },
    showReplies: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0.5),
      fontSize: "0.8125rem",
      fontWeight: 600,
    },
    moreActionsContainer: {},
    bodyWrapper: {
      padding: "8px",
      borderRadius: "12px",
      backgroundColor: theme.mode.background.comment,
      marginLeft: theme.spacing(1.5),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      minWidth: 284,
      "& .header": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        "& svg": {
          width: 16,
          height: 16,
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(1),
      },
    },
    body: {
      "& .ql-container": {
        wordBreak: "break-word",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.9375rem",
        lineHeight: "1.6rem",
        color: theme.mode.text.commentBody,
      },
      "& .ql-clipboard": {
        display: "none",
      },
      "& .ql-editor": {
        padding: 0,
      },
    },
    ownerName: {
      fontSize: "0.8125rem",
      fontWeight: "800",
      color: theme.mode.text.formLabel,
      lineHeight: "1.85",
      display: "flex",
      alignItems: "center",
      gap: 4,
      "& svg": {
        height: 17,
        width: 16,
      },
    },
    likesCount: {
      color: theme.mode.text.heading,
      fontWeight: "800",
      fontSize: "0.6875rem",
      lineHeight: "1.45",
      position: "absolute",
      left: "calc(100% - 31px)",
      bottom: -23,
      padding: 0,
      height: 18,
      borderRadius: 24,
      backgroundColor: theme.mode.background.comment,
      display: "flex",
      gap: "2px",
      alignItems: "center",
      boxShadow: "0px 0px 0px 4px " + theme.mode.background.light,
      cursor: "pointer",
    },
    likesCountIcon: {
      color: theme.palette.common.white,
      backgroundColor: theme.mode.icon.background,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 3,
    },
    likesCountNumberContainer: {
      display: "flex",
      alignItems: "center",
    },
    likesCountNumber: {
      color: theme.mode.text.heading,
      fontSize: "0.6875rem",
      fontWeight: "bold",
      padding: theme.spacing(0, 0.5, 0, 0.25),
    },
    icon: { width: 18, height: 18, fill: "#757575", marginLeft: 2 },
    iconSelected: { fill: theme.palette.primary.main },
    actionsSpinner: {
      height: 20,
      marginLeft: theme.spacing(2),
    },
    avatar: {
      width: 24,
      height: 24,
    },
    commentHighlighted: {
      backgroundColor: theme.mode.background.commentHighlighted,
    },
    imageCarousel: {
      margin: theme.spacing(0, -1.5),
      width: `calc(100% + ${theme.spacing(3)})`,
      "& .slider": {
        margin: theme.spacing(0, 1),
        width: `calc(100% - ${theme.spacing(2)})`,
      },
    },
    defaultCursor: {
      cursor: "default",
    },
  }),
);
