import React from "react";

import { Grid, CardHeader, Link, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import UserAvatar from "@/common/components/UserAvatar";
import { PostProps } from "@/posts/components/PostCard";
import PostCardActions from "@/posts/components/PostCardActions";

import VerifiedIcon from "@/common/icons/VerifiedIcon";

import { getLocalDateFromUtc } from "@/common/services";
import { getFullAuthorName } from "@/common/services/fullName";
import { UserBaseFragment, PostFragment } from "@/graphql/types";

const useStyles = makeStyles<void, "avatar" | "subheader">()((theme: Theme, _params, classes) => ({
  container: {
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5, 1.5, 0, 1.5),
    },
  },
  avatarContainer: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  avatar: {
    width: 40,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: 32,
      height: 32,
    },
  },
  title: {
    color: theme.mode.text.heading,
    fontSize: "1.0625rem",
    lineHeight: "normal",
    display: "inline-block",
    "& svg": {
      height: 15,
      width: 15,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9375rem",
    },
  },
  createdBy: {
    fontSize: "0.8125rem",
    fontWeight: 600,
    lineHeight: "normal",
    color: "#7d7d8c",
    "& span": {
      display: "flex",
      gap: "3px",
      alignItems: "center",
      "& svg": {
        width: 13,
        height: 13,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6875rem",
    },
  },
  creationDate: {},
  subheader: {
    fontSize: "0.8125rem",
    fontWeight: 600,
    lineHeight: "normal",
    color: "#a9a9b2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  cardHeader: {
    paddingLeft: 0,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
    },
  },
  includesAdditionalInfo: {
    [`& .${classes.avatar}`]: {
      width: 60,
      height: 60,
      [theme.breakpoints.down("sm")]: {
        width: 32,
        height: 32,
      },
    },
    [`& .${classes.subheader}`]: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6875rem",
      },
    },
  },
  authorNameWrapper: {
    cursor: "default",
  },
  profileLink: {
    cursor: "pointer",
  },
  postCardActionsGrid: {
    paddingTop: "0px !important",
  },
  voteOrientationBadge: {
    padding: "9px 0",
    borderRadius: "6px",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minWidth: "95px",
    textAlign: "center",
    fontSize: "0.8125rem",
    fontWeight: "800",
    lineHeight: "1.08",
    letterSpacing: "0.4px",
    userSelect: "none",

    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5625rem",
      lineHeight: "1.33",
      minWidth: "64px",
    },
  },
  badgeInFavor: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  badgeAgainst: {
    background: theme.mode.debate.no,
    color: theme.palette.secondary.contrastText,
  },
}));

type PostCardHeaderProps = PostProps & {
  onEditClicked?: (post: PostFragment) => void;
  onEditTriggered?: (post: PostFragment) => void;
};

function PostCardHeader({
  post,
  showAdditionalInfo,
  postActivity,
  onEditTriggered,
  onEditClicked,
  timelinePinnable,
  isRestricted,
}: PostCardHeaderProps) {
  const { cx, classes } = useStyles();
  const { t, i18n } = useTranslation();
  const isAdmin = postActivity?.author?.id === post?.author?.id;
  const profileLink =
    post.anonymous || post.author?.deleted ? undefined : `/profiles/${post.author?.slug}`;

  const Title = () => (
    <>
      {showAdditionalInfo && postActivity ? (
        <>
          <span className="notranslate">
            <Link
              underline="none"
              href={profileLink}
              className={cx(
                classes.authorNameWrapper,
                profileLink ? classes.profileLink : undefined,
              )}>
              <Typography variant="h4" color="textSecondary" className={classes.title}>
                <b>
                  {getFullAuthorName(post.author as UserBaseFragment, post.anonymous)}{" "}
                  {!post.anonymous && post.author && post.author.verified && <VerifiedIcon />}
                </b>
              </Typography>
            </Link>
            &nbsp;
            <Typography variant="h4" className={classes.title}>
              {isAdmin ? t("Timeline.HasSharedAnUpdateOn") : t("Timeline.PostedOn")}
              &nbsp;
            </Typography>
          </span>
          <Link underline="none" href={postActivity.url}>
            <Typography variant="h4" className={classes.title}>
              <b>{postActivity.title}</b>
            </Typography>
          </Link>
          <Typography className={classes.createdBy}>
            <span className="notranslate">
              {t("Generic.CreatedBy")}&nbsp;
              {getFullAuthorName(postActivity.author as UserBaseFragment, post.anonymous)}
              {!post.anonymous && post.author && post.author.verified && <VerifiedIcon />}
            </span>
          </Typography>
        </>
      ) : (
        <Link
          underline="none"
          href={profileLink}
          className={cx(classes.authorNameWrapper, profileLink ? classes.profileLink : undefined)}>
          <Typography variant="h4" color="textSecondary" className={classes.title}>
            <span className="notranslate">
              <b>
                {getFullAuthorName(post.author as UserBaseFragment, post.anonymous)}{" "}
                {!post.anonymous && post.author && post.author.verified && <VerifiedIcon />}
              </b>
            </span>
          </Typography>
        </Link>
      )}
    </>
  );

  const ProfileAvatar = () => (
    <Link
      underline="none"
      href={profileLink}
      className={cx(classes.authorNameWrapper, profileLink ? classes.profileLink : undefined)}>
      <UserAvatar user={post?.author} isAnonymous={post.anonymous} className={classes.avatar} />
    </Link>
  );

  return (
    <div className={classes.container}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <CardHeader
            classes={{
              avatar: classes.avatarContainer,
              subheader: classes.subheader,
              root: showAdditionalInfo ? classes.includesAdditionalInfo : classes.cardHeader,
            }}
            avatar={<ProfileAvatar />}
            title={<Title />}
            subheader={
              <Moment
                locale={i18n.language}
                fromNow={!showAdditionalInfo}
                format={showAdditionalInfo ? "MMM DD, YYYY" : undefined}>
                {getLocalDateFromUtc(post.createdAt)}
              </Moment>
            }
            subheaderTypographyProps={{ color: "textPrimary" }}
            p={0}
          />
        </Grid>
        {post.voteOrientation == "in_favor" && (
          <div
            className={cx(classes.voteOrientationBadge, {
              [classes.badgeInFavor]: post.voteOrientation == "in_favor",
            })}>
            {t("Generic.Yes")}
          </div>
        )}
        {post.voteOrientation == "against" && (
          <div
            className={cx(classes.voteOrientationBadge, {
              [classes.badgeAgainst]: post.voteOrientation == "against",
            })}>
            {t("Generic.No")}
          </div>
        )}
        {isRestricted || (
          <Grid item className={classes.postCardActionsGrid}>
            <PostCardActions
              post={post}
              postActivity={postActivity}
              onEditTriggered={onEditTriggered}
              onEditClicked={onEditClicked}
              timelinePinnable={timelinePinnable}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default PostCardHeader;
