import { useReportCommentMutation } from "@/graphql/types";

import { CommentUnion } from "../types/CommentUnion";

export const useCommentsReportsStore = (comment: CommentUnion) => {
  const [report] = useReportCommentMutation();

  return {
    report({ type, comment: commentBody }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: comment.id,
            type,
            comment: commentBody,
          },
        }).then(({ data }) => {
          if (data?.reportComment?.successful) {
            resolve(data?.reportComment?.result);
          } else {
            reject(data?.reportComment);
          }
        });
      });
    },
  };
};
