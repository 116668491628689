import React from "react";

import { CommentView } from "@/comments/components/CommentView/CommentView";
import { ReadOnlyProps } from "@/posts/components/PostCard";

import { useCommentsLikeStore } from "@/comments/hooks";
import { usePostsCommentStore } from "@/posts/hooks";

import { ActivityCardFragment, ActivityCardSlimFragment } from "@/common/models/ActivityModel";
import { CommentReplyFragment, PostCommentFragment } from "@/graphql/types";

const PostComment = ({
  comment,
  isReadonly,
  onReadonlyInteraction,
  onFollowableInteraction,
  postActivity,
  isHighlighted,
  highlightedReplyId,
  isRestricted,
  onRestrictedAction,
  onEditComment,
  onReplyToComment,
}: {
  comment: PostCommentFragment;
  onFollowableInteraction?: () => void;
  postActivity?: ActivityCardFragment | ActivityCardSlimFragment;
  isHighlighted?: boolean;
  highlightedReplyId?: string;
  isRestricted?: boolean;
  onRestrictedAction?: () => void;
  onEditComment: (comment: PostCommentFragment | CommentReplyFragment) => void;
  onReplyToComment?: (
    comment: PostCommentFragment | CommentReplyFragment,
    parentCommentId: string,
  ) => void;
} & ReadOnlyProps) => {
  const { deleteComment, loading } = usePostsCommentStore();
  const { toggleLike, toggleLikeLoading } = useCommentsLikeStore(comment);

  return (
    <CommentView
      key={comment.id}
      comment={comment}
      variant="comment"
      isReadonly={isReadonly}
      isRestricted={isRestricted}
      onFollowableInteraction={onFollowableInteraction}
      onReadonlyInteraction={onReadonlyInteraction}
      onRestrictedAction={onRestrictedAction}
      postActivity={postActivity}
      handleLike={toggleLike}
      handleEdit={() => onEditComment(comment)}
      handleReplyEdit={(reply) => onEditComment(reply)}
      handleOnReply={(comment, id) => {
        onReplyToComment && onReplyToComment(comment, id);
      }}
      handleDelete={deleteComment}
      loading={loading || toggleLikeLoading}
      isHighlighted={isHighlighted}
      highlightedReplyId={highlightedReplyId}
    />
  );
};

export default PostComment;
