import dynamic from "next/dynamic";
import React from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";

import type { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";
import type { ReportAbuseDialogProps } from "@/common/components/ReportAbuseDialog";
import { ReadOnlyProps } from "@/posts/components/PostCard";

import CrownBadgeIcon from "@/common/icons/CrownBadgeIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useCommentsReportsStore } from "@/comments/hooks";
import { bindDialogState, useDialog } from "@/common/hooks";
import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";

import { ActivityCardFragment, ActivityCardSlimFragment } from "@/common/models/ActivityModel";
import { ReportActivities } from "@/common/models/Enums";
import { ReportReason } from "@/common/models/Report";

import { CommentUnion } from "../types/CommentUnion";
const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);
const ReportAbuseDialog = dynamic<ReportAbuseDialogProps>(
  () => import("@/common/components/ReportAbuseDialog").then((module) => module.ReportAbuseDialog),
  {
    ssr: false,
  },
);

const useStyles = makeStyles()(() => ({
  button: {
    padding: 0,
    color: "#a9a9b2",
  },
}));

export type CommentActionsProps<C extends CommentUnion> = {
  comment: C;
  handleEdit?: (reply?: C) => void;
  handleDelete?: (comment: C) => void;
  postActivity?: ActivityCardFragment | ActivityCardSlimFragment;
} & ReadOnlyProps;

export default function CommentActions<C extends CommentUnion>({
  comment,
  postActivity,
  handleEdit,
  handleDelete,
}: CommentActionsProps<C>) {
  const { classes } = useStyles();
  const { session } = useAuthenticationStore();
  const user = session?.user;
  const popupState = usePopupState({
    variant: "popover",
    popupId: "commentMenu",
  });
  const { t } = useTranslation();

  const canDelete =
    handleDelete && (comment.author?.isMe || postActivity?.author?.id === session?.user?.id);
  const canEdit = handleEdit && comment.author?.isMe;
  const canReport = !comment.author?.isMe;

  const confirmDialogState = useConfirmDialog();
  const dialogState = useDialog<ReportReason>();
  const { report } = useCommentsReportsStore(comment);

  function handleReport() {
    dialogState.open();
    popupState.close();
  }

  function handleEditComment() {
    popupState.close();
    if (!user?.activeSubscription) return;
    handleEdit && handleEdit(comment);
  }

  function confirmDelete() {
    popupState.close();
    confirmDialogState.open({
      title: t("Timeline.DeleteComment"),
      message: t("Timeline.AreYouSureYouWantToDeleteComment"),
      onConfirm: () => handleDelete && handleDelete(comment),
    });
  }

  return (
    <>
      <ConfirmDialog dialogState={confirmDialogState} />
      <ReportAbuseDialog
        {...bindDialogState(dialogState)}
        type={ReportActivities.genericActivity}
        onReport={report}
        user={comment.author}
      />
      <Grid container>
        <IconButton
          size="small"
          classes={{ root: classes.button }}
          {...bindTrigger(popupState)}
          data-cy="CommentActions__toggle-menu">
          <MoreHorizIcon />
        </IconButton>

        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          {...bindMenu(popupState)}>
          {canReport && (
            <MenuItem onClick={handleReport} data-cy="CommentActions__report">
              {t("Timeline.ReportComment")}
            </MenuItem>
          )}
          {canEdit && (
            <MenuItem onClick={handleEditComment} data-cy="CommentActions__edit">
              <Tooltip
                style={{ display: "flex", marginTop: 5 }}
                title={
                  (user && !user.activeSubscription
                    ? t("Monetization.Generic.callToSubscribeTooltip")
                    : "") || ""
                }
                arrow
                placement="right">
                <div>
                  <span>{t("Timeline.EditComment")}</span>
                  <div style={{ marginLeft: 15, marginTop: 2 }}>
                    <CrownBadgeIcon blackBadge />
                  </div>
                </div>
              </Tooltip>
            </MenuItem>
          )}
          {canDelete && (
            <MenuItem onClick={confirmDelete} data-cy="CommentActions__delete">
              {t("Timeline.DeleteComment")}
            </MenuItem>
          )}
        </Menu>
      </Grid>
    </>
  );
}
