import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

// @ts-ignore
export default makeStyles()((theme: Theme) => ({
  textContainer: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5),
    },
  },
  cardContent: {
    padding: theme.spacing(0, 0, "20px", 0),
    margin: 0,
    "& .ql-container": {
      wordBreak: "break-word",
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.0625rem",
      color: theme.mode.text.description,
    },
    "& .ql-clipboard": {
      display: "none",
    },
    "& .ql-editor": {
      padding: 0,
    },
    "&:last-child": {
      paddingBottom: theme.spacing(0.5),
    },
  },
  chip: {
    height: "20px",
    margin: theme.spacing(0.5, 0.5, 0, 0),
    backgroundColor: theme.mode.background.default,
    color: theme.palette.grey["400"],
    ...theme.typography.caption,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 0),
  },
  imageCarousel: {
    "& .slider": {
      margin: theme.spacing(0, 2.5),
      width: `calc(100% - ${theme.spacing(5)})`,
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(0, 1),
        width: `calc(100% - ${theme.spacing(2)})`,
      },
    },
  },
  files: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5),
    },
  },
}));
